    import React, {useState, useEffect, useContext} from 'react'
import {Button, Badge, DropdownItem, DropdownMenu, ButtonDropdown, DropdownToggle, Modal, ModalBody, ModalFooter, Input, Label, FormGroup} from 'reactstrap'
import StoreContext from '../../core/storeContext'
import getFilters, {setFilter, updateFilter, getFilter} from './apiActions'
import storeContext from '../../core/'

export function FiltersRow ({selectedFlatRows, selectedRowIds, filterData, saveFilterEnabled, setTableFilter, allParams }){
    const [dropdownOpen, setDropdownOpen] = React.useState(false)
    const {token} = useContext(storeContext)
    const [list, setList] = useState([ {id: '1', name: "filtro 1"}, {id: '2', name: "filtro 2"} ])
  
    const [filterSelected, setFilterSelected] = useState(null)

    const toggleFilter = () => setDropdownOpen(!dropdownOpen)

    useEffect( () => {
        getFilters(token)
        .then(res => {
            setList(res.data)
        })
    }, [token])

    return <>
    <Button 
    title="Selezionati"
     color="primary" 
     size="sm" 
     disabled={!saveFilterEnabled}
     onClick={()=>{
      // elementi selezionati:
          let ids = []
          selectedFlatRows.forEach ( item => {
            ids.push(item.original.id)
        })
        
        filterData( { records: ids, filters: allParams })
        return true
       }
    }>
      <i className="fa fa-save"></i>&nbsp;Salva Filtro&nbsp;
      <Badge pill color="primary">{Object.keys(selectedRowIds).length}</Badge>
      </Button>
      &nbsp;
      <ButtonDropdown isOpen={dropdownOpen} toggle={toggleFilter} color="primary" 
     size="sm" >
      <DropdownToggle caret>
        Filtri Salvati
      </DropdownToggle>
      <DropdownMenu>
          { list.map ( item => {
             return <DropdownItem key={item.id} onClick={()=>{
                 getFilter(token, item.id)
                 .then( res => {
                    
                    setTableFilter(res.data)
                 })
                 .catch( err => {
                     console.log(err)
                     //setTableFilter(["601189acb321cb70ce379b97", "601189acb321cb70ce379b99", "601189acb321cb70ce379b9a"]) //TODO: togliere, è  solo per test.
                 })
                 setFilterSelected( {id: item.id, name: item.name})
                
            }}>{item.name}</DropdownItem>
          })}
      </DropdownMenu>
    </ButtonDropdown>
    &nbsp; 
    {filterSelected ? <Button 
        title={filterSelected.name}
     color="secondary" 
     size="sm" 
     disabled={!saveFilterEnabled}
     >{filterSelected.name} <a href={window.location.href} className="text-danger" 
     title="rimuovi filtro" 
     onClick={()=> {
         setFilterSelected(null)
         setTableFilter([])
         }}><i className="fa fa-times text-danger"></i></a>
    
    </Button> : null

    }
    
    </>
} 

export default function FilterModal({item, isOpen, toggle, isEdit=false}) {

    const {token} = useContext(StoreContext)  
    const [filter, setDataFilter] = useState({item})

    useEffect(() => {

        setDataFilter(item)

    }, [item])

    function onFilterChange (e, field) {
        const tempFilter = filter
        tempFilter[field] = e.target.value
        setDataFilter(tempFilter)
    }

    function saveFilter(){
    
        if(filter && filter.name && filter.values)
        {
            if(!isEdit){
                return setFilter(token, filter)
            .then( (resp) => {
                console.log(resp)
                alert('Dato Salvato')
                window.location.reload()
            }).catch( err => {
                console.log( err.response,)
                alert( "Dato non salvato!\n" + err.response.data)
            })
            }
            else {
                return updateFilter(token, filter)
                .then( (resp) => {
                    console.log(resp)
                    alert('Dato Salvato')
                    window.location.reload()
                }).catch( err => {
                    console.log( err.response,)
                    alert( "Dato non salvato!\n" + err.response.data)
                }) 
            }
            
        }
            
        return alert('Inserisci il Nome.')
        
    }

   return <><Modal isOpen={isOpen} toggle={toggle}>
        <ModalBody>
            <h3>Inserimento nuovo Filtro</h3>
            <FormGroup>
                <Label> Nome </Label>
                <Input name="name" type="text" onChange={(e)=>onFilterChange(e, 'name')} placeholder="nome del filtro" value={filter.name} />
            </FormGroup>
           {/**
            * <FormGroup>
               <Label>
                Tipo</Label>
                <Input name="type" type="text" onChange={(e)=>onFilterChange(e, 'type')} disabled value={filter.type} />
            </FormGroup>
            * 
            */} 
            <FormGroup>
                <Label>Records</Label>
                <Input name="values" type="textarea" disabled={true} value={filter && filter.values ? JSON.stringify(filter.values) : ""}  />
            </FormGroup>
            <FormGroup>
               <Label>Condivisione</Label>
                <Input name="shared" type="select" value={filter.element} onChange={(e)=>onFilterChange(e, 'shared')} >
                    <option value="0">No</option>
                    <option value="1">Organizzazione</option>
                    <option value="2">Proprio Gruppo</option>
                </Input>
           </FormGroup>
        </ModalBody>
        <ModalFooter>
            <Button color="primary" size="lg" onClick={()=> saveFilter()}>Salva</Button>
        </ModalFooter>
    </Modal>
    </>

}