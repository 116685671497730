import axios from 'axios'
import config from '../../../config'

const {appAdmin} = config;
const {api} = appAdmin.filter( (item) => (item.nameSpace === "learningPath"))[0]

export default function getLearningPaths(token) {

    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }
    return axios.get(api.getLearningPaths, axiosConfig)
}

export function updateCourses(token,id,data) {

    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }
    return axios.put(api.setCourses + "/" + id, data, axiosConfig)
}

export function getLearningPath(token, id) {

    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }
    return axios.get(api.getLearningPaths + "/" + id, axiosConfig)
}

export function createLearningPath(token, data) {

    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }
    return axios.post(api.getLearningPaths, data, axiosConfig)
}

export function getLanguages(token) {
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }

    return axios.get(api.getLanguages, axiosConfig)
}