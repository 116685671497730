/**
 * Attenzione, ogni componente aggiunto, deve essere anche incluso in App.js
 * 
 * 
 */

/**
 * successivamente dobbiamo riuscire a fare il build con gitlab-runner e variabili d'ambiente
 * l'ideale sarebbe creare un immagine docker
 * const basePath = (process.env['NODE_ENV']==='production')? process.env.REACT_BASE_PATH_PROD:REACT_BASE_PATH_TEST
 *
 * per il momento hard-coded e il basepath è scelto runtime
 * https://test-strapi-loginoidc.goto.unime.it/v_events
*/

const PRODUCTION = window.location.hostname === 'unimestone.unime.it'
//const LOCAL = window.location.hostname === 'localhost'

let basePath = ""

if(PRODUCTION){
  basePath = 'https://unimestone-backend.goto.unime.it'
} else {
  basePath = 'https://unimestone-test.devsciam.azure.unime.it/api'
}

const debugLocal = localStorage.getItem('debug') === 'true'

// gruppi d'utente
const REFERENTI = ['RefDepStud', 'Admin'] // RefDepStud è il gruppo dei referenti di didattica del dipartimento
//const ADMIN = 'Admin'
const AUTENTICATI = ['Authenticated'] // Tutti gli utenti autenticati

const config = {
  siteLogo: window.location.origin + '/assets/logo_UniME_bw_lr.png',
  siteTitle: "UniMeStone",
  siteLabel: " ",
  debug: debugLocal || false, //!PRODUCTION, 
  defaultLanguage: 'it-IT',
  languages: [
    { code: "it-IT",  text: "Italiano", img: "it.png"},
    { code: "en-EN", text: "English", img: "gb.png"}
  ],
  flagBaseUrl: "/assets/flags/",
  helpButton: {enabled: true, url: "https://sway.office.com/fYYq8IBABdoPEw7h?ref=Link", title:"Guida all'uso di UnimeStone"},
  siteImg: "/assets/landing.jpg",
  altImg: "Login to Rosetta Stone Unime",
  auth : {
    login : basePath + '/connect/simplesaml',
    logout : 'https://minosse.unime.it/simplesaml/saml2/idp/SingleLogoutService.php?ReturnTo=' + window.location.origin,
    getStrapiToken : basePath + '/auth/simplesaml/callback'
  },

  users : { 
      api: basePath + '/users/me'
    },
  app : [
    {
      nameSpace: 'core',
      role: AUTENTICATI,
      hidden: { menu : true, route: true, component: false, userMenu: true},
      path: "/my",
      api: {
        getMessages: basePath + "/messages",
        getConfigs: basePath + "/configs"
      }
    },
    {
      nameSpace: 'my',
      role: AUTENTICATI,
      default: "Authenticated",
      hidden: { menu : false, route: false, component: false, userMenu: true},
      menuLabel: 'I miei obiettivi',
      pageTitle: "I miei obiettivi",
      pageSubTitle: "",
      annotations: "",
      path: '/my',
      image: window.location.origin + '/assets/checklist.png',
      Component: 'MyGoal',
      attributes: {Anno: 2020},
      api : {
          getMyGoals : basePath +'/users-goals/my',
          getCurrent : basePath + '/users-goals/my/current',
          setChoice: basePath + "users-goals/my",
          getConfig: basePath + "/configs",
          setProfile: basePath + "/user-data/update-profile",
          profiles: basePath + "/user-data/profiles"
      }
    },
    {
      nameSpace: 'myProfile',
      hidden: { menu : true, route: false, component: false, userMenu: false},
      role: AUTENTICATI,
      menuLabel: 'Profilo Utente',
      pageTitle: "Profilo Utente",
      pageSubTitle: "",
      annotations: "",
      path: '/my-profile',
      image: window.location.origin + '/assets/checklist.png',
      Component: 'MyProfile',
      attributes: {Anno: 2020},
      api : {
          setProfile: basePath + "/user-data/update-profile"
      }
    },
    {
      nameSpace: 'goal',
      role: AUTENTICATI,
      hidden: { menu : true, route: false, component: false, userMenu: true},
      annotations: "",
      path: '/users-goals/byId/:id',
      image: window.location.origin + '/assets/checklist.png',
      Component: 'UserGoal',
      attributes: {},
      api : {
          getConfig  : basePath + '/configs',
          getGoal : basePath + '/users-goals/',
          setChoice: basePath + "/users-goals/my",
          setConfig: basePath + '/users-goals/config/',
          setStatus: basePath + '/users-goals/status/',
          setDate: basePath + '/users-goals/date/'
      }
    },
    {
      nameSpace: 'UserGoalsList',
      role: AUTENTICATI,
      hidden: { menu : true, route: false, component: false, userMenu: true},
      menuLabel: 'Obiettivi',
      pageTitle: "Obiettivi selezionabili",
      annotations: "",
      path: '/users-goals/list',
      image: window.location.origin + '/assets/checklist.png',
      Component: 'UserGoalsList',
      attributes: {},
      api : {
          getList : basePath + '/users-goals/my/choice',
          setChoice: basePath + "/users-goals/my"
      }
    },
    {
      nameSpace: 'UsersPlacements',
      role: AUTENTICATI,
      hidden: { menu : true, route: true, component: false, userMenu: true},
      menuLabel: 'Placements',
      pageTitle: "Placement List",
      annotations: "",
      path: '/users-goals/placements',
      image: window.location.origin + '/assets/checklist.png',
      Component: 'UsersPlacements',
      attributes: {},
      api : {
          getPlacements : basePath + '/reports/rosettastone/placement_tests',
      }
    },
    
  ],
  // Attivo solo per ADMIN...
  appAdmin : [
    {
      nameSpace: 'users',
      role: REFERENTI,
      hidden: { menu : true, route: false, component: false},
      menuLabel: 'Users',
      pageTitle: "Lista Utenti",
      annotations: "",
      path: '/admin/users',
      image: window.location.origin + '/assets/checklist.png',
      Component: 'ReportUsers',
      attributes: {},
      api : {
          getUsers : basePath + '/reports/users/'
      },
    },
    {
      nameSpace: 'placements',
      role: REFERENTI,
      menuLabel: 'Placement Tests',
      pageTitle: "Placement Tests",
      annotations: "",
      path: '/admin/placements/:email',
      image: window.location.origin + '/assets/checklist.png',
      Component: 'ReportPlacements',
      attributes: {},
      api : {
          getPlacements : basePath + '/reports/rosettastone/placement_tests',
      }
    },
    {
      nameSpace: 'learningPath',
      role: REFERENTI,
      menuLabel: 'Learning Paths',
      pageTitle: "Learning Paths",
      annotations: "",
      path: '/admin/learningpath',
      image: window.location.origin + '/assets/checklist.png',
      Component: 'LearningPath',
      attributes: {},
      api : {
          getLearningPaths : basePath + '/goals/learning_path',
          setCourses: basePath + '/goals/learning_path/courses',
          getLanguages: basePath + '/languages',
      }
    },
    {
      nameSpace: 'userGoals',
      role: REFERENTI,
      menuLabel: 'User Goals',
      pageTitle: "User Goals",
      annotations: "",
      path: '/admin/user-goals/:email',
      image: window.location.origin + '/assets/checklist.png',
      Component: 'ReportGoals',
      attributes: {},
      api : {
          getUsersGoals : basePath + '/reports/users-goals',
      }
    },
    {
      nameSpace: 'Goals',
      role: REFERENTI,
      menuLabel: 'Goals',
      pageTitle: "Goals",
      annotations: "",
      path: '/admin/goals',
      image: window.location.origin + '/assets/checklist.png',
      Component: 'Goals',
      attributes: {},
      api : {
          getGoals : basePath + '/goals',
          setGoal: basePath + '/goals',
          getLanguages: basePath + '/languages',
          getDeparmtents: basePath + '/departments'
      }
    },
    {
      nameSpace: 'user',
      role: REFERENTI,
      hidden: { menu : true, route: false, component: false},
      menuLabel: 'Utente',
      pageTitle: "Vista Utente",
      annotations: "",
      path: '/user/:email',
      image: window.location.origin + '/assets/checklist.png',
      Component: 'User',
      attributes: {},
      api : {
          getUser : basePath + '/reports/users',
          getUserGoals : basePath + '/reports/users-goals',
          getPlacementsTest : basePath + '/reports/rosettastone/placement_tests'
      }
    },
    {
      nameSpace: 'filters',
      role: REFERENTI,
      hidden: { menu : true, route: false, component: false},
      menuLabel: 'Filtri',
      pageTitle: "Elenco Filtri",
      annotations: "",
      path: '/admin/filters',
      image: window.location.origin + '/assets/checklist.png',
      Component: 'Filters',
      attributes: {},
      api : {
          getFilters : basePath + '/reports/unimestone/filters/',
          setFilter : basePath + '/reports/unimestone/filters/',
      }
    },
  ]
}

export default config
