import React from 'react'
import {Col, Row, Card,  CardBody, Button} from 'reactstrap'
import {Link, Redirect} from 'react-router-dom'
import {config} from '../'
import validUserRole from '../../utils/validUserRole'

const errorMessages  = {
    forbidden: "Accesso non consentito"
}

const CardWidgets = ({role}) =>{

    const Elementi = config.app.map( (item , idx) => {
        const isValid = validUserRole(item.role, role)

        if(!isValid)
            return false
        
        const openDefault = item.default && (role.indexOf(item.default) > -1) && isValid //
        if(isValid && openDefault){
            return <Redirect to={item.path} key={item.path} />
        }

        if(isValid && !item.hidden)
            return (
            <Col md={{size: 3}} sm={{size:6}} xs={{size:12}} key={idx}>
                <Card>
                    <CardBody>
                        <Link to={item.path}>
                            <Button color="primary" size="lg" block>{item.pageTitle}</Button>
                        </Link>
                    </CardBody>
                </Card>
            </Col>
            )
      
        return null
    })

    const isNotEmptyArray = Elementi.filter( item => item !== null).length

    return(
        <Row className="justify-content-center">
            {isNotEmptyArray ? Elementi : <Col><h4>{errorMessages.forbidden}</h4></Col>}
        </Row>
    )
}

export default CardWidgets

