import React, {useState, } from 'react'
import {  FormGroup,  Badge, Label, FormText, Input, Button, 
Nav, NavLink, NavItem,  TabPane, Row, Col} from 'reactstrap'
import validationSchema, {initialValues} from './config';
import { Formik, Form, Field} from 'formik';
import MarkDownEditor, { RestrictedFields} from './customControls';
import "./edit.css"
import remapData, {remapInputData} from './utils'

const infoArray = [ 'name', 'cod_dip', 'cod_cds', 'des_cds', 'classe', 'des_af', 'cod_af', 'peso', 'minTotHours' ]
const restLabels = { 'realm' :  "Domini: (unime.it / studenti.unime.it)",'cod_cds' : "Codice del CDS",'matricola' : "Matricola",'username' : "UserName", 'cod_role' : "Codice del Ruolo"}
const restrictions = Object.keys(restLabels)
const infoArray1 = infoArray.slice(0,-1)

export default function Editor ({languages, departments, submitFunction, goal, resetElenco, isAdmin}) {
    const [activeTab, setActiveTab] = useState(1);
    const setTab = (val) => {setActiveTab(val)}

    const LanguagesOptions = languages.map( 
        (item) =>( <option value={item.id} key={item.id}>
            {item.description}
        </option>)
        )
    
    const DepartmentOptions = departments.map( (item) =>( <option value={item.cod_dip} key={item.cod_dip}>
            {item.des_dip}
        </option>))

    
    return <>
        <Row className="justify-content-center">
            <Col md={11}>
            <Formik
                initialValues={remapInputData(goal, restrictions)}
                validationSchema={validationSchema}
                validateOnBlur={true}
                validateOnChange={true}
                validateOnMount={false}
                validateOnSubmit={true}
                handleReset ={ () => {
                    console.log('resetting')
                }}
                onSubmit={(values, actions) => {
                    const dataToSend = remapData(values, infoArray, restrictions)
                    const c = window.confirm ( "sei sicuro di salvare il goal?")
                    if (c) {
                        submitFunction(dataToSend)
                        .then( res => {
                             resetElenco()
                            const s = window.confirm('Goal Salvato. Vuoi resettare i dati del form?')
                            if(s){
                                actions.resetForm({
                                    values: initialValues,
                                })
                            } 
                        })
                        .catch( err => {
                            console.log(err)
                            alert(err.response.data.message)
                        })
                    }
                }}
            >
            {(formikProps) => {

                const {errors, touched, setFieldValue} = formikProps
            const notePage1 = !! Object.keys(errors) ? Object.keys(errors).filter( item => ['languages', 'type'].indexOf(item) > -1 ).length : null
            const notePage2 = !! Object.keys(errors) ? Object.keys(errors).filter( item => infoArray1.indexOf(item) > -1 ).length : null
            const notePage3 = !! errors && errors.restrictTo ? 1 : null
               // console.log(errors)
            return (
                <Form>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={activeTab === 1 ? 'active' : ''} onClick={()=>setTab(1)}>
                                Tipo e Lingua {notePage1>0 && <Badge pills="true" color="danger">{notePage1}</Badge>}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                            className={activeTab === 2 ? 'active' : ''} onClick={()=>setTab(2)}>
                                Dettagli {notePage2>0 && <Badge pills="true" color="danger">{notePage2}</Badge>}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={activeTab === 3 ? 'active' : ''} onClick={()=>setTab(3)}>
                                Restrizioni {notePage3>0 && <Badge pills="true" color="danger">{notePage3}</Badge>}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={activeTab === 4 ? 'active' : ''} onClick={()=>setTab(4)}>
                                Messaggi
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabPane tabId="1">
                        {activeTab === 1 ? <><Row>
                        <Col md={6}>
                        <br style={{paddingTop: 10}} />
                                <FormGroup>
                                    <Label htmlFor="type">Tipo</Label>
                                    <Field as="select" className="form-control" name="type">
                                        <option value="">Scegli il tipo di goal</option>
                                        <option value="linguisticSuitabilityStudent">Idoneità Linguistica</option>
                                        <option value="accreditationTrainingCredits">Accreditamento Crediti Formativi Generici</option>
                                        <option value="lessonSupport">Supporto alle lezioni</option>
                                        <option value="custom">Personalizzato (non obbligatori dettagli)</option>
                                    </Field>
                                    { (!!(errors.type) ) ? <p className="alert alert-danger">{errors.type}</p> : null}
                                </FormGroup>
                                <FormGroup>
                                    <div className="form-check form-switch">
                                        <Input tag={Field} type="checkbox" className="form-check-input" id="monitoring" name="monitoring"
                                        />
                                        <label className="form-check-label" htmlFor="monitoring">Monitoring</label>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <label htmlFor="languages">Lingue</label><br/>
                                    <Field as="select" name="languages" size="6" className="form-select" multiple 
                                    >
                                        {LanguagesOptions}
                                    </Field>
                                    <FormText>Per selezionare più lingue, premere il pulsante <i>CTRL + Click</i></FormText>
                                    { (!!(errors.languages && touched.languages) ) ? <p className="alert alert-danger">{errors.languages}</p> : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="justify-content-end">
                            <Col md={2} className="align-self-end text-right">
                                <Button color="primary" size="lg" onClick={()=>setTab(2)}><i className="fa fa-arrow-circle-right"></i>&nbsp;Avanti</Button>
                            </Col>
                        </Row></> : null}
                        
                    </TabPane>
                    <TabPane tabId="2">
                        {activeTab === 2 && <><Row>
                            <Col md={6}>
                            <br style={{paddingTop: 10}} />
                            <FormGroup>
                                <Label htmlFor="name">Nome Goal</Label>
                                <Input tag={Field} type="text" name="name" />
                                { (!!(errors.name) ) ? <p className="alert alert-danger">{errors.name}</p> : null}
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="cod_dip">Dipartimento</Label><br/>
                                <Field  
                                    as="select" 
                                    name="cod_dip" 
                                    tag={Field}
                                    className="form-select"
                                    >
                                        <option value="">Scegli dipartimento</option>
                                        <option value="NN">Dipartimento non specificato (fittizio)</option>
                                        {DepartmentOptions}
                                </Field>
                                { (!!errors.cod_dip && !!touched.cod_dip)
                                    ?<p className="alert alert-danger">{errors.cod_dip}</p> 
                                    : null}
                            </FormGroup>
                                <FormGroup>
                                <Label htmlFor="cod_cds">Codice CDS</Label>
                                <Input type="text" name="cod_cds"
                                tag={Field} />
            
                                { (!!errors.cod_cds && !!touched.cod_cds) 
                                    ? <p className="alert alert-danger">{errors.cod_cds}</p> 
                                    : null}
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="des_cds">Descrizione CDS</Label>
                                    <Input type="text" name="des_cds" 
                                        tag={Field}/>
                                    { (!!( !!errors.des_cds && touched.des_cds)) ? <p className="alert alert-danger">{errors.des_cds}</p> : null}
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="classe">Classe</Label>
                                    <Input type="text" name="classe"
                                       tag={Field}
                                    />
                                    { (!!(errors.classe && touched.classe)) ? <p className="alert alert-danger">{errors.classe}</p> : null}
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <br style={{paddingTop: 10}} />
                                <FormGroup>
                                <Label htmlFor="cod_af">Codice Attività Formativa</Label>
                                <Input tyoe="text"name="cod_af"
                                    tag={Field}
                                />
                                { (!!( errors.cod_af && touched.cod_af)) ? <p className="alert alert-danger">{errors.cod_af}</p> : null}
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="des_af">Descrizione Attività Formativa</Label>
                                <Input type="text" name="des_af" 
                                tag={Field}
                                />

                                { (!!(errors.des_af && touched.des_af)) ? <p className="alert alert-danger">{errors.des_af}</p> : null}
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="peso">Peso CFU</Label>
                                <Input name="peso" type="number" 
                                tag={Field}
                                onChange={
                                    (e) => {
                                        setFieldValue('peso', parseInt(e.target.value))
                                        setFieldValue('minTotHours', parseInt(e.target.value)*25 )
                                    }
                                }
                                />
                                { (!!(errors.peso && touched.peso)) ? <p className="alert alert-danger">{errors.peso}</p> : null}
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="minTotHours">Numero minimo di ore del goal</Label>
                                <Input tag={Field} name="minTotHours" type="number" />
                                { (!!(errors.minTotHours && touched.minTotHours)) ? <p className="alert alert-danger">{errors.minTotHours}</p> : null}
                            </FormGroup>
                            </Col>
                        </Row>
                        <Row className="justify-content-between">
                                <Col md={2} >
                                    <Button color="primary" size="lg" onClick={()=>setTab(1)}><i className="fa fa-arrow-circle-left"></i>&nbsp;Indietro</Button>
                                </Col>
                                <Col md={2} >
                                    <Button color="primary" size="lg" onClick={()=>setTab(3)}><i className="fa fa-arrow-circle-right"></i>&nbsp;Avanti</Button>
                                </Col>
                            </Row></>}
                    </TabPane>
                    <TabPane tabId="3">
                        {activeTab === 3 && <Row>
                            
                            <Col><br style={{paddingTop: 10}} />
                                <Row >
                                {restrictions.map ( item => <Col md={6} key={item}><FormGroup> 
                                        <Label htmlFor={`restrictTo[${item}]`}>{restLabels[item]}</Label>
                                        <RestrictedFields tipe="text" name={`restrictTo[${item}]`} />
                                        <FormText>Si possono anche usare più elementi <i>{restLabels[item]}</i>, separati da virgola ",".</FormText>   
                                    </FormGroup></Col>)
                                }
                                </Row>
                        {/*<FormGroup>
                                <Label htmlFor="restrictTo.realm">Domini</Label>
                                <Input name="restrictTo.realm" 
                                tag={Field}
                                />
                                <FormText>(unime.it / studenti.unime.it) Si possono anche usare più domini, separati da virgola ",".</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="restrictTo.cod_cds">Corsi di Studio</Label>

                                <Input tipe="text" name="restrictTo.cod_cds"  
                                tag={Field} />
                                <FormText>Si possono anche usare più Corsi di Studio (per codice), separate da virgola ",".</FormText>   
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="restrictTo.matricola">matricola</Label>
                                <Input type="text" name="restrictTo.matricola"
                                tag={Field}
                                />
                                <FormText>Si possono anche usare più matricole, separate da virgola ",".</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="restrictTo.username">Nome Utente</Label>
                                <Input type="text" name="restrictTo.username"
                                tag={Field}
                                />
                                <FormText>Si possono anche usare più nomi utente, separati da virgola ",".</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="restrictTo.cod_role">Codice Ruolo Utente</Label>
                                <Input type="text" name="restrictTo.cod_role"
                                tag={Field}
                                />
                                <FormText>Si possono anche usare più codici ruolo utente, separati da virgola ",".</FormText>
                            </FormGroup> 
                        */}
                            { (!!(errors.restrictTo)) ? <p className="alert alert-danger">{errors.restrictTo}</p> : null}
                            </Col>
                            <Col md={12}>
                                <Row className="d-flex justify-content-between">
                                    <Col md={2} className="p-2">
                                        <Button color="primary" size="lg" onClick={()=>setTab(2)}><i className="fa fa-arrow-circle-left"></i>&nbsp;Indietro</Button>
                                    </Col>
                                    <Col md={2} className="mt-auto p-2 text-right">
                                    <Button color="primary" size="lg" onClick={()=>setTab(4)}><i className="fa fa-arrow-circle-right"></i>&nbsp;Avanti</Button>
                                    </Col>
                                </Row>
                            </Col>
                            
                        </Row>}
                    </TabPane>
                    <TabPane tabId="4">
                    {activeTab === 4 && <Row>
                        <Col md={6}>
                            <br style={{paddingTop: 10}} />
                            <FormGroup>
                                <Label htmlFor="choiceMessageCustom">Messaggio "di Scelta" personalizzato</Label>
                                <MarkDownEditor name="choiceMessageCustom" />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <br style={{paddingTop: 10}} />
                            <FormGroup>
                                <Label htmlFor="dashboardMessageCustom">Messaggio "Dashboard" personalizzato</Label>
                                <MarkDownEditor name="dashboardMessageCustom" />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <Row className="d-flex justify-content-between">
                                <Col md={2} className="p-2">
                                    <Button color="primary" size="lg" onClick={()=>setTab(3)}><i className="fa fa-arrow-circle-left"></i>&nbsp;Indietro</Button>
                                </Col>
                                <Col md={2} className="mt-auto p-2 text-right">
                                    {isAdmin ? <Button type="submit" size="lg" color="success"><i className="fa fa-save"></i>&nbsp;Invia</Button> : null}
                                </Col>
                            </Row>
                        </Col>
                    </Row>}
                    </TabPane>
                </Form>)}}
            </Formik>
            </Col>
        </Row>
    <br />
    <br />
    </>

}