import React from 'react'
import {Alert, Progress} from 'reactstrap'

const defaultFormat = 'DD/MM/YYYY [alle ore] H:mm'

const Report = ({userGoal}) => {
    const {minTotHours} = userGoal
    
 const report = userGoal.report || {}
 report["data"] =  {
     report_date:"2023-08-30",
     completed: true,
     courses:[
       {
          course_guid:"e95a2f225eac1f8b2031f7f619a97943",
          course_name:"Introduce yourself",
          course_lang:"en_US",
          course_progress:"0.00714286000000000000",
          course_grade:"1.00000000000000000000",
          course_required: true,
          course_suggested: null       
       },
       {
        course_guid:"e95a2f225eac1f8b2031f7f619a97944",
        course_name:"Introduce herself",
        course_lang:"en_US",
        course_progress:"0.80714286000000000000",
        course_grade:"1.00000000000000000000",
        course_required: true,
        course_suggested: null       
     }
     ]
  }

    return <Alert color="success"></Alert>
    /* da rivedere */   
    const avviso = "(il primo report potrebbe essere disponibile dopo 15-20 giorni dall'avvio dell'obiettivo, non prima di Gennaio 2021)"

    
    if(!report){
       return <Alert color="success">
            {minTotHours ? <p className="text-center">Ore Totali: <b>--</b>/<b>{minTotHours} ore</b></p> : null }
            <p className="text-left">Data ultimo Monitoraggio: <b>Spiacente 
                non è disponibile al momento un report<br/></b>
                <em>{avviso}</em>
                </p>
            </Alert>
    }   

   return <>
    <table className="table table-striped">
        <tr>
            <th>Corso</th>
            <th>Grado di completamento</th>
            <th>Info</th>
        </tr>
        <tbody >
            {report.data.courses.map( item => <tr key={item.course_guid}>
                <td>{item.course_name} [{item.course_lang}]</td>
                <td>{item.course_progress > 0.01 ? <Progress value={item.course_progress*100}>{(item.course_progress*100).toFixed(2)}%</Progress> : "<1\%"}</td>
                <td className="text-center">
                    {report.data.completed === true ? <i className="fa fa-award text-success" title="Completed"></i> : null} {` `}
                    {item.course_required ? <i className="fa fa-map-pin" title="Required"></i>:null}
                </td>
            </tr>)}
        </tbody>
    </table>
   </>
}
export default Report
