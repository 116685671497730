import axios from 'axios'
import config from '../../config'
const {app} = config;
const {api} = app.filter( (item) => (item.nameSpace === "UserGoalsList"))[0]

function formatData (id) {
    const data = {goal: id}
    if(config.debug)
        data.backdoor = 1
    return data 
}

export function setGoal(token, id) {
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }

    return axios.post(api.setChoice, formatData(id), axiosConfig)
}

export default async function getList (token) 
{
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }
    
    return axios.get(api.getList, axiosConfig)
}

