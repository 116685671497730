import jwtDecode from 'jwt-decode'
import moment from 'moment'
// eslint-disable-next-line no-unused-vars
import momentTimezone from 'moment-timezone'


export function formatDate (date){
    if(!date) 
        return ""
  
    const data = moment(date)
    .tz('Europe/Rome')
    .format('DD/MM/YYYY HH:mm')

    if(data === "Invalid date")
        return " "

    return data
  }

/**
 * TOKEN TOOLS
 * @param {*} token 
 * @param {*} user 
 */
export function saveData(token) {
    
    console.log('saving data.')
    console.log(token)
    localStorage.setItem('token',token)
    return true
}


export function clearData() {
    localStorage.clear('token')
    localStorage.clear('user')
    return Promise.resolve()
}

/**
 * getToken prende il token dal localstorage e lo controlla con la data corrente del browser.
 */
export default function getToken () {
    
    let error = ""

    try{

        const tok = localStorage.getItem('token') 
        
     /*   
        if(!tok)
             return Promise.reject({message: "Necessario login.", token: ""})
       */ 
        const token = tok //JSON.parse(tok)

        const jwt = jwtDecode(token)
        let exp = moment.unix(jwt.exp*1000)
        let now = moment.unix(moment())
        
        const expirated = exp.isSameOrBefore(now)

        if(expirated)
           return Promise.reject({message: "Token scaduto."})
        
        if(token){
            return Promise.resolve({token:token})
        }    
    }catch(err){
        error = err.message || "Accesso non eseguito o sessione scaduta."
    }
       
    return Promise.reject({message: error || "Token non esistente."})
}

export function getLanguage () {
    try{
       return Promise.resolve(localStorage.getItem('language'))
    }catch(err){
        return Promise.reject(null)
    }
}