
import * as Yup from 'yup'

const idoneita = "linguisticSuitabilityStudent"

const initialValues = {
    type: "",
    monitoring: false,
    languages: [],
    name: "",
    info: {
        cod_dip: "",
        cod_cds: "",
        des_cds: "",
        classe: "",
        des_af: "",
        cod_af: "",
        choiceMessageCustom: "",
        dashboardMessageCustom: "", 
        peso: 0,
    },
    minTotHours: 0,
    restrictTo: {
        realm: "",
        cod_cds:"",
        matricola: "",
        username: "",
        cod_role: ""
    }
}

const validationSchema = Yup.object({
    type: Yup.string().required('Il tipo di Goal è indispensabile.'),
    languages: Yup.array().min(1, "Scegli almeno una lingua.").required("Scegli almeno una lingua."),
    monitoring: Yup.boolean(),
    name: Yup.string().when('type', {is: !idoneita, then: Yup.string().required("Impostare un nome per definire il Goal")}),
    cod_dip: Yup.string().when('type', {is: idoneita, then: Yup.string().required("Impostare il dipartimento")}),
    cod_cds: Yup.string().when('type', {is: idoneita, then: Yup.string().required("Impostare il codice del Cds")}),
    des_cds: Yup.string().when('type', {is: idoneita, then: Yup.string().required("Impostare la descrizione del CDS")}),
    classe: Yup.string().when('type', {is: idoneita, then: Yup.string().required("Impostare la Classe")}),
    des_af: Yup.string().when('type', {is: idoneita, then: Yup.string().required("Impostare la descrizione Attività Formativa")}),
    cod_af: Yup.string().when('type', {is: idoneita, then: Yup.string().required("Impostare il Codice Attività Formativa")}),
    peso: Yup.number().when('type', {is: idoneita, then: Yup.number().min(1, "Impostare almeno 1 CFU").positive().required("Impostare il Peso in CFU")}),
    choiceMessageCustom: Yup.string(),
    dashboardMessageCustom: Yup.string(),
    minTotHours: Yup.number(),
    restrictTo: Yup.object({
        realm: Yup.string(),
        cod_cds: Yup.string(),
        cod_role: Yup.string(),
        matricola: Yup.string(),
        username: Yup.string()
    }).test(
        'restrictTo', 
        "Bisogna impostare almeno un elemento nella restrizione del goal", 
        value => !!(value.realm || value.cod_cds|| value.matricola || value.cod_role || value.username)
        ).required('Impostare almeno un parametro di restrizione.')
    })
       
export default validationSchema
export {initialValues}