import React from 'react'
import {Alert} from 'reactstrap'
//import config from '../../config'
import {CustomMessage} from '../'

const GoalInfo = ({goal, notitle} ) => {
    const { info} = goal
    //const theads = info ? Object.keys(info) : null
    const DesCds = info.des_cds ? <p>Corso di Studi: <b>{info.des_cds}</b></p>  : ""
    const DesPds = info.des_pds ? <p>Piano di Studi: <b>{info.des_pds}</b></p> : ""
    const message = info.dashboardMessageCustom || null

    //console.log(info)
    if(DesCds || DesPds || message)
    return <><Alert color="secondary">
        {message ? <CustomMessage message={message} />: null}
        {DesCds}
        {DesPds}
        {/*info && info.choiceMessageCustom ? <p className="text-info">{info.choiceMessageCustom}</p> : null
        
        <p className="text-secondary small">Code: <b>{code}</b></p>
        <p className="text-secondary small">Type: <b>{type}</b></p>
        */
        }
    {/*
    config.debug && theads.length ?
        <table className="table table-striped table-bordered">
            <thead>
                <tr>
                    <td>Chiave</td>
                    <td>Valore</td>
                </tr>
            </thead>
            <tbody> 
                {theads.map( col => {
                    return <tr key={col}><td>{col.toUpperCase()}</td><td>{info[col]}</td></tr>
                })}
            </tbody>
        </table>
     : null 
            */}
     </Alert>
 </>

 return null
}

export default GoalInfo