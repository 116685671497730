
import React from 'react'
import {Col, Card, CardBody, CardFooter, CardTitle, Button, Alert} from 'reactstrap'
import {CustomMessage} from "../.."

const GoalContent = ({editGoal, goal, languagesList, isAdmin}) =>{
  const {name, minTotHours, languages, info} = goal
  const message = info.choiceMessageCustom
  const DesCds = info.des_cds ? <p>Corso di Studi: <b>{info.des_cds}</b></p>  : ""
  const DesPds = info.des_pds ? <p>Piano di Studi: <b>{info.des_pds}</b></p> : ""
  const ElencoLingue = `${languagesList.find( x => x['id'] === languages[0]).description}, ${languages[1] ? languagesList.find( x => x['id'] === languages[1]).description : ""} ${languages.length > 2 ? `, ...[${languages.length - 2} altre lingue]` : ""}`

  return <Col md={4}>
    <Card style={{marginBottom: 36}} >
    <CardBody>
        <CardTitle>
        <h5>{name}</h5> <i style={{fontSize: "80%"}}>id: {goal.id}</i>
        </CardTitle>  
        
          {minTotHours ? <p>Ore totali: <b>{minTotHours}</b></p> : null}
        
        <p>
         Lingue disponibili: <b>{ElencoLingue}</b>  
        </p>
        {DesCds}
        {DesPds}
        {message ? <Alert><CustomMessage message={message} /></Alert> : ""}
        {info && info.note ? <p>{info.note}</p> : ""}
    </CardBody>
    <CardFooter>
        <Button color="primary" onClick={()=>{
          editGoal(goal); 
          }}>{  isAdmin ? "Modifica" : "Visualizza" }</Button>
    </CardFooter>
      </Card>
  </Col>
}
export default GoalContent
