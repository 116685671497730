import axios from 'axios'
import config from '../../config'
const {app} = config;
const {api} = app.filter( (item) => (item.nameSpace === "goal"))[0]

function formatData (data) {
    /*
    if(config.debug)
        data.backdoor = 1
        */
    return data
}

export default async function getGoalById ({token, id}) 
{
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }
    
    return axios.get(api.getGoal + id, axiosConfig)
}

export function setConfig(token, id, data) {
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }

    return axios.put(api.setConfig + id, formatData(data), axiosConfig)
}

export function setStatus(token, id, data) {
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }

    return axios.put(api.setStatus + id, formatData(data), axiosConfig)
}

export function getConfig(token) {
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }

    return axios.get(api.getConfig, axiosConfig)
}

export function setChoice(token, id) {
    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }

    return axios.post(api.setChoice, formatData({goal: id}), axiosConfig)
}