import React, {useState, useEffect, useCallback} from 'react'
import {useParams} from 'react-router-dom'
import StoreContext from '../../core/storeContext'
import getUser, {getUserPlacementsTest, getUserGoals} from './apiActions'
import {Spinner, Button, Collapse} from 'reactstrap'
import {ElencoPlacements, remap as remapPlacements} from '../../placements/'
import {ElencoUserGoals, remap} from '../../my/'


export default function User () {
    const {email} = useParams();

    //const preFilter = email !== ":email" ? [{ id: 'email', value: email}] : null
  
    const [user, setUser] = useState([])
    const [placements, setPlacements] = useState([])
    const [userGoals, setUserGoals] = useState([])
    const {token} = React.useContext(StoreContext) 
    const [isOpenGoals, setIsOpenGoals ] = useState(false)
    const [isOpenPlacements, setIsOpenPlacements] = useState(false)
    const [isLoadingPlace, setIsLoadingPlace] = useState(true)
    const [isLoadingGoal, setIsLoadingGoal] = useState(true)
    const [isLoading, setIsLoading] = useState(true)

    const togglePlacements= ()=>{
        setIsOpenPlacements(!isOpenPlacements)
    }

    const toggleGoals= useCallback(()=>{
        setIsOpenGoals(!isOpenGoals)
    }, [isOpenGoals])

    const getPlacements = () =>{
        setIsLoadingPlace(true)
        getUserPlacementsTest(token, email)
        .then(res => {
         
            setPlacements(remapPlacements(res.data))
        })
        .catch( err => {
            console.log(err)
        })
        .then( () => {
            setIsLoadingPlace(false)
        })
    }

    const getGoals = React.useCallback ( () =>{
        setIsLoadingGoal(true)
        getUserGoals(token, email)
        .then( res => {
            setUserGoals(remap(res.data))
        })
        .catch(err => {
            console.log(err)
        })
        .then(()=>{
            setIsLoadingGoal(false)
        })
    }, [token, email])

    useEffect(() => {
        console.log('user email')
        setIsLoading(true)
        getUser(token, email)
        .then(res => {
            setUser(res.data)
        })
        .catch(err=> {
            console.log(err.response) 
            alert("Non è stato possibile visualizzare i dettagli dell'utente.\n" + err.response.data )
        })
        .then( () => {
            setIsLoading(false)
            getGoals()
            toggleGoals()
        })
    }, [token, email, getGoals, toggleGoals])

    if(isLoading)
        return <Spinner color="primary" />



    return <>
    <h3> {user[0].firstName} {user[0].lastName} <small className="text-muted">[{user[0].matricola}]</small></h3>  


    <div>
        <Button color="link" onClick={() => {
        toggleGoals()
        if(!userGoals.length)
            getGoals()
    }}> Vedi User Goals  <i className={`fa fa-caret-${isOpenGoals ? 'up': 'down'}`}></i></Button>
    <Collapse isOpen={isOpenGoals} >
        
    {isLoadingGoal && <><br /><Spinner color="primary"/></>}
    {userGoals && userGoals.length ? <><ElencoUserGoals goals={userGoals} />
        </> : <p>Non ci sono Goals per l'utente...</p>}
    </Collapse>
    
    </div>

    <div>
        <Button color="link" onClick={() => {
        togglePlacements()
        if(!placements.length)
            getPlacements()
            
    }}>Vedi Placements Tests <i className={`fa fa-caret-${isOpenPlacements ? 'up': 'down'}`}></i></Button>
    <Collapse isOpen={isOpenPlacements} >
        {isLoadingPlace && <><br /><Spinner color="primary" /></>}
        {placements && placements.length ? <>
        <ElencoPlacements placements={placements} />
        </> : <p>Non ci sono placements per l'utente.</p>}
    </Collapse>
    </div>

</>


}
