// Componente per la gestione del profilo
// Visualizza USERDATA, 
// Pulsante di modifica e 
// pulsante per salvare (aggiornare il profilo online).
// al salvataggio del profilo
import React, {useContext, useState, useEffect} from 'react'
import {Row, Col, Button} from 'reactstrap'
import StoreContext from '../core'
import setProfile, {getProfiles, putProfiles} from "./apiActions"
import ProfileList from './profileList'

const MyProfile = () => {
    const {userData, token} = useContext(StoreContext)
    const [profileList, setProfileList] = useState([])

    const updateProfile = () => {
        const c = window.confirm("Sicuro di voler aggiornare il profilo?\n")
        
        if(c !== true)
            return null

        setProfile(token)
          .then( resp => {
               alert(resp.data.message)
                window.location.reload()
          })
          .catch( err => {
              try{
                  alert( "Attenzione\n" + err.response.data.message.toString())
              }
              catch(error){
                  console.log(error, err)
              }
          })
        }

    useEffect(() => {
           const getUserProfiles = () => {
        getProfiles(token)
            .then( resp => {
                setProfileList(resp.data)
            })
        }

        getUserProfiles()
    }, []);
 

    const setNewProfile = (id) => {
        const c = window.confirm("Sicuro di voler aggiornare il profilo?\n")
        
        if(c !== true)
            return null

        putProfiles(token, id)
            .then( resp => {
                alert(resp.data.message)
                window.location.reload()
            })
            .catch( err => {
                try{
                    alert( "Attenzione\n" + err.response.data.message.toString())
                }
                catch(error){
                    console.log(error, err)
                }
            })
        }

    const {firstName, lastName, matricola, cod_cds= null, 
        des_cds = null, cod_dip = null , des_dip = null, 
        cod_role=null, des_role=null, des_profile = null, 
        des_category = null, teacher = null } = userData



    return (
        <Row className="d-flex justify-content-center">
            <Col md={8}>
            <p className="text-secondary"> 
            Utente: <b>{firstName} {lastName}</b> [{matricola}] 
            </p>
            {cod_dip ? <p className="text-secondary"> Dipartimento: <b>[{cod_dip}] - {des_dip}</b> </p> : ""}
            {cod_cds ? <p className="text-secondary"> Corso di Studi: <b>[{cod_cds}] - {des_cds}</b> </p> : ""}
            {cod_role ? <p className="text-secondary">Ruolo: [{cod_role}] {des_role}</p> : ""}
            {des_profile ? <p>Profilo:  {des_profile}</p>: ""}
            {profileList? <ProfileList current={userData.cod_profile} list={profileList} update={(id)=>setNewProfile(id)} />: ""}
            {des_category ? <p>Categoria: {des_category}</p> : ""}
            {teacher === 'S' ? <p className="text-secondary">Docente</p> : ""}
            <Button color="primary" onClick={updateProfile} > Aggiorna Profilo </Button>
            </Col>
        </Row>
    )
}

export default MyProfile