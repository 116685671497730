import React, {useState} from 'react'
import {Alert, Row, Col} from 'reactstrap'
//import MessageContext from '../core'
//import TemplateComponent from 'react-mustache-template-component'
import List from './goalsList'
import getList from './apiActions'
import SupportTeam from '../support'


export default function UserGoalsList({token, user}){
    const [elenco, setElenco] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState("")
    React.useEffect( () => {

        getList(token)
        .then(res => {
            setElenco(res.data)
        })
        .catch(err=>{
            console.log(err)
            setError(err.message.toString())
        })
        .then( () => {
            setLoading(false)
        })

    }, [token])
/*
    const {message} = React.useContext(MessageContext)
    if(!message.length)
        return <Spinner />

   // user.fullName = user.cognome ? user.cognome + " " + user.nome : user.username
    //const  {template} = message.find( el => el._id === "CORE_WELCOME_MESSAGE")

    const data = {}
    data.user = user

*/
    return <>
    {/*
        <Row className="justify-content-center">
            <Col className="text-center">
                <TemplateComponent template={template['it-IT'].component} data={data} />
            </Col>
        </Row>
    */}
    
        <Row className="justify-content-center">
            <List loading={loading} elenco={elenco} token={token} />
            <SupportTeam token={token} user={user} />
        </Row>
        {error ? <Row className="justify-content-center">
            <Col>
                <Alert color="danger">
                    {error}
                </Alert>
            </Col>
        </Row> : ""}
    </>
  
}
