import React, {useState} from 'react'
import TablePaged from '../table_paged'
import columns, {hiddenColumns} from './columns'
import StoreContext from '../../core'

import config from '../../../config'
import {useParams} from 'react-router-dom'
import {formatDate} from '../../../utils'

const {appAdmin} = config;
const {api,pageTitle} = appAdmin.filter( (item) => (item.nameSpace === "userGoals"))[0]

function flatData(data){
  if(!data || !data.length)
    return Promise.reject([])

  const t = []
  data.map( item => {
    if(item.userData)
      try  {  
        const { startedAt = "-", verifiedAt = "-", closedAt = "-", canceledAt="-", minTotHours = "-", status = "-", current = "-", id = "-",  currentTargetLanguage = "-"} = item
        const {firstName = "-", lastName = "-", matricola = "-", email = "-", ssoId = "-", cod_cds = "-", des_cds = "-", cod_dip="-", des_dip ="-", des_ord = "-", des_pds ="-"} = item.userData

        t.push( { 
          nome: firstName,
          cognome: lastName,
          matricola,
          email,
          ssoId,
          cds : `[${cod_cds}] ${des_cds}`,
          dip: `[${cod_dip}] ${des_dip}`,
          ord: des_ord,
          pds: des_pds,
          current, 
          userGoalId: id,
          goalId: item.goal.id,
          goalName: item.goal.name,
          language: currentTargetLanguage && currentTargetLanguage.description ? currentTargetLanguage.description : "--" ,
          minTotHours,
          status, startedAt:formatDate(startedAt) ,  verifiedAt: formatDate(verifiedAt), closedAt: formatDate(closedAt), canceledAt: formatDate(canceledAt)
        })
      } catch (err) {
        console.log(err, item)
      }
    return item
  })   

  return  Promise.resolve(t)
}

export default function ReportGoals(){

  const {token} = React.useContext(StoreContext) 
  const [loading] = useState(true)
  const { email } = useParams();
  const preFilter = email !== ":email" ? [{ id: 'email', value: email}] : null

 /* React.useEffect( 
    () => 
    {
      console.log('usergoals')
      getUserGoals(token)
      .then( res => {
        const data = flatData(res.data)
        setData( data)
      })
      .then(()=>{
        setLoading(false)
      })
      .catch( err => {
        console.log('ERRORE.' + err)
        setLoading(false)
      })

    }, [token])
*/
 return <>
   <h2 style={{padding: 24}}>{pageTitle}</h2>
   <span style={{fontSize: '0.9em'}}>
     <TablePaged 
     url={api.getUsersGoals}
     remap={data => data //flatData(data)
      }
     remapExport={data => flatData(data)}
     token={token}
     loading={loading} 
     columns={columns}  
     useAdvance={true}
     hiddenColumns={hiddenColumns} 
     preFilter={preFilter} 
     /> 
   </span>
  
 </>
}