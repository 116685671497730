
import React from 'react'
import {Button} from 'reactstrap'

export default function ProfileList ({current, list, update}){

    return<> 
    { 
        list.map( (item) => {
            if(item.id!==current)
                return <p>{item.id} - {item.description} <Button color="link" onClick={()=>{update(item.id)}}>Imposta</Button></p>
            return null
        }) 
        
    }
</>


}