import React, {useState} from 'react'
import Table from '../table'
import columns, {hiddenColumns} from './columns'
import StoreContext from '../../core'
import getUsers from './apiActions'
import config from '../../../config'

const {appAdmin} = config;
const { pageTitle} = appAdmin.filter( (item) => (item.nameSpace === "users"))[0]

export default function ReportUsers(){

  const {token} = React.useContext(StoreContext) 
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  React.useEffect( 
    () => 
    {
      getUsers(token)
      .then( res => {
        const {data} = res
        setData(data)
      })
      .then(()=>{
        setLoading(false)
      })
      .catch( err => {
        console.log('ERRORE.' + err)
        setLoading(false)
      })
      
      
    }, [token])


    
 return <>
   <h2 style={{padding: 24}}>{pageTitle}</h2>
  <Table data={data} columns={columns} loading={loading} hiddenColumns={hiddenColumns} />
 </>
}