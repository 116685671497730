const courseList = {
    "courses": [
        {
            "Learning language": "de-DE",
            "CEFR": "B1",
            "Course topic": "Business Meetings",
            "Course title": "Meetings und Präsentationen - B1",
            "Course ID": "519037fd852e98cbc8a6cee7268ae5f5"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B2",
            "Course topic": "Business Meetings",
            "Course title": "Meetings und Präsentationen - B2",
            "Course ID": "e960cae167a8c97c493cd050e8a10a0b"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1",
            "Course topic": "Culture",
            "Course title": "Landeskunde - B1",
            "Course ID": "cfffd477-c797-dd6a-0517-02e3535ca98c"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B2",
            "Course topic": "Culture",
            "Course title": "Landeskunde - B2",
            "Course ID": "2f4550a1-2073-867a-50f2-56d8089e39ce"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "C1",
            "Course topic": "Culture",
            "Course title": "Landeskunde - C1",
            "Course ID": "ec589816-a3f1-f1b9-f3aa-0598db713957"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1,B2,C1",
            "Course topic": "Culture",
            "Course title": "Videos: Kultur und Gesellschaft",
            "Course ID": "edc2b4d05df1dc6bc22db12e1d55377d"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1,B2,C1",
            "Course topic": "Culture",
            "Course title": "Videos: Politik und Wirtschaft",
            "Course ID": "3963ef843d73f5105064b82f7da179e3"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B2",
            "Course topic": "Customer Service",
            "Course title": "Kundendienst - B2",
            "Course ID": "49eea481-bcca-2109-feda-d9da249d147c"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "C1",
            "Course topic": "Customer Service",
            "Course title": "Kundendienst - C1",
            "Course ID": "9a06e8181beeb8ec64de7a23375ca8d8"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1",
            "Course topic": "Customer Service",
            "Course title": "Kundenkontakte pflegen - B1",
            "Course ID": "2a81c18cb9cde0a365509c9cb970483b"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B2",
            "Course topic": "Customer Service",
            "Course title": "Kundenkontakte pflegen - B2",
            "Course ID": "c6334500ba0ff3dfa5bef2880c35e24d"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Alle Kompetenzbereiche - B2",
            "Course ID": "b8ae8019819d9981d03bdbc9f229002f"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "C1",
            "Course topic": "Everyday Situations",
            "Course title": "Alle Kompetenzbereiche - C1",
            "Course ID": "fd262efe14d91f601af6a72078c99d2e"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Alle Kompetenzbereiche (B1)",
            "Course ID": "731deab7aef6e578f2007bee5c3e631f"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Alle Kompetenzbereiche (B1)",
            "Course ID": "731deab7aef6e578f2007bee5c3e631f"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Kompetenzschwerpunkt: Grammatik - B2",
            "Course ID": "5fc9e83c982c187e133be46476692a71"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "C1",
            "Course topic": "Everyday Situations",
            "Course title": "Kompetenzschwerpunkt: Grammatik - C1",
            "Course ID": "878d3be9540bd9a663e6194c98818409"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Kompetenzschwerpunkt: Grammatik (B1)",
            "Course ID": "3f8ea92cc65733eb7571f2a195efc321"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Kompetenzschwerpunkt: Hörverstehen und mündlicher Ausdruck - B2",
            "Course ID": "46ebc3787a3a36f95201437ecb578895"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "C1",
            "Course topic": "Everyday Situations",
            "Course title": "Kompetenzschwerpunkt: Hörverstehen und mündlicher Ausdruck - C1",
            "Course ID": "1567e5f469bbc74713ec9d6b898c54c6"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Kompetenzschwerpunkt: Leseverstehen und schriftlicher Ausdruck - B2",
            "Course ID": "23c898106f132fac85a225d18550bb39"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "C1",
            "Course topic": "Everyday Situations",
            "Course title": "Kompetenzschwerpunkt: Leseverstehen und schriftlicher Ausdruck - C1",
            "Course ID": "be0633791fad2120e29a55bb6e5f849a"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Kompetenzschwerpunkt: Wortschatz - B2",
            "Course ID": "b7de9c7e09f881b52fcafc6fb1ea8276"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "C1",
            "Course topic": "Everyday Situations",
            "Course title": "Kompetenzschwerpunkt: Wortschatz - C1",
            "Course ID": "9a17f5f6bdc8f9ace4226655da86deec"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Kompetenzschwerpunkt: Wortschatz (B1)",
            "Course ID": "067f9af7af36516d17aa0b922bf5b6b0"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Kompetenzschwerpunkte: Hören und Sprechen (B1)",
            "Course ID": "6e7833ebe4c1e525fbfafef0a3569424"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Kompetenzschwerpunkte: Lesen und Schreiben (B1)",
            "Course ID": "1aabac06d1e003dad024891ee9b818ce"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B2",
            "Course topic": "Finance and Economics",
            "Course title": "Finanzen",
            "Course ID": "e1a0f48d67b7adc4491513d5b150f10d"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "C1",
            "Course topic": "Finance and Economics",
            "Course title": "Finanzwesen",
            "Course ID": "be7415cc1a8bc93c41d17c806f6b342d"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1",
            "Course topic": "General Business Communication",
            "Course title": "Projektmanagement - B1",
            "Course ID": "fedd1d9dae7247ae9d6c5ec3e84b268f"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B2",
            "Course topic": "General Business Communication",
            "Course title": "Projektmanagement - B2",
            "Course ID": "624d1d336eb67f8a86d831326df20516"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Allgemeine Anatomie",
            "Course ID": "438362d6ecc16517c5d5915a3fe8f740"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Allgemeine Anatomie: Glieder und Gelenke",
            "Course ID": "1ff75093-b1c6-5edd-d6a5-182e0f7ecafd"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Allgemeine Anatomie: Kopf und Gesicht",
            "Course ID": "4338433e-169c-02a6-97cc-89f1a127694a"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Behandlungsmethoden",
            "Course ID": "f3afb04e-3095-5a5d-ecf2-0ebdf280b930"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Diagnostik ",
            "Course ID": "a2ff3383-11e7-c326-edb2-30ca0ddcc18c"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Im Krankenhaus",
            "Course ID": "b39ecd2b-9d76-e93f-8e88-61b7dbbc2f1c"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Operationen",
            "Course ID": "c5290cd1-2b0e-73e5-958c-160885c82627"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Patientenpflege",
            "Course ID": "843cf22245b26e064cb024c4b59102c9"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Patientenpflege: Pallativmedizin",
            "Course ID": "66c6623a-fd8c-af96-1ad7-fa4b940e4f25"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Patientenpflege: Schwangerschaft und Geburt",
            "Course ID": "97d1dbbf6f09e539f256c248580ed30e"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Videos: Gesundheit und Medizin",
            "Course ID": "c6cf51a5-7b2a-919b-ddc7-dd2f66f468b2"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1",
            "Course topic": "Human Resources",
            "Course title": "Personalwesen - B1",
            "Course ID": "24547b19cbbe85adb6501f74b7f66b05"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B2",
            "Course topic": "Human Resources",
            "Course title": "Personalwesen - B2",
            "Course ID": "e0c99e900322770f2daf7bdc7a1a7caf"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1",
            "Course topic": "Legal",
            "Course title": "Buchhaltung",
            "Course ID": "26e5e849a410562a480c9f4eced880a1"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1",
            "Course topic": "Legal",
            "Course title": "Vertragliche Vereinbarungen - B1",
            "Course ID": "960ed690113232d2089316b4f38e4c3c"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "C1",
            "Course topic": "Legal",
            "Course title": "Vertragliche Vereinbarungen - C1",
            "Course ID": "32588ae79d55c12a9b15d94b28215e18"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1,B2,C1",
            "Course topic": "Technology",
            "Course title": "Videos: Technologie",
            "Course ID": "ab7468a0-bc4b-1b71-90c7-0a2ea04952d5"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1,B2,C1",
            "Course topic": "The Environment and Physical Sciences",
            "Course title": "Videos: Weltraum",
            "Course ID": "2400b0e6389524cf486dc6ac585681f0"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B1,B2,C1",
            "Course topic": "The Environment and Physical Sciences",
            "Course title": "Videos: Wissenschaft",
            "Course ID": "01c916cba0f8f6ee3d9e95d5f451c605"
        },
        {
            "Learning language": "de-DE",
            "CEFR": "B2",
            "Course topic": "Travel and Hospitality",
            "Course title": "Geschäftsreisen",
            "Course ID": "61c3ae1b9809e9213fa0d884a37a2a01"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "Aviation",
            "Course title": "Speak with Pilots and Airline Mechanics (B1)",
            "Course ID": "e9a0ece4d98d0a0b3e9a2e5b5c977eb1"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Aviation",
            "Course title": "Speak with Pilots and Airline Mechanics (B2)",
            "Course ID": "d4c274706142ca4ac314d61abbd914bf"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "Aviation",
            "Course title": "Speak with Pilots and Airline Mechanics (C1)",
            "Course ID": "1e11bc50e032eab40f517c6e88a531a8"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "Business Meetings",
            "Course title": "Lead, Communicate and Listen in Meetings (B1)",
            "Course ID": "0b1dcc54dba29230766844be8f1f228c"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Business Meetings",
            "Course title": "Lead, Communicate and Listen in Meetings (B2)",
            "Course ID": "ef8f8661f7c5fa3e59f1fe028fbe1350"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "Business Meetings",
            "Course title": "Lead, Communicate and Listen in Meetings (C1)",
            "Course ID": "147d2d4b326c43c5bac4430c6cd39a9e"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "Career and Employment",
            "Course title": "Manage Your Career (B1)",
            "Course ID": "9156489ce5918431e2578ca12690339e"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Career and Employment",
            "Course title": "Manage Your Career (B2)",
            "Course ID": "a566bc86d701cd3553f8786365ca05cd"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "Career and Employment",
            "Course title": "Manage Your Career (C1)",
            "Course ID": "3eb0b4f87be31267f2757e3c81dd7e17"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "Culture",
            "Course title": "Cultural Exercises (B1)",
            "Course ID": "d5ac954116e4e9b455d1b287a7d791b6"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Culture",
            "Course title": "Cultural Exercises (B2)",
            "Course ID": "7324ea5e2c40630ee41c780b30096bc6"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "Culture",
            "Course title": "Cultural Exercises (C1)",
            "Course ID": "3efd51d0b033e5c71fe8696a8e6fc381"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1,B2,C1",
            "Course topic": "Culture",
            "Course title": "Video Lessons: Culture and Society",
            "Course ID": "0c3ea95f93c0f7711152d516bcfc9a06"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "Customer Service",
            "Course title": "Develop Customer Service Skills (B1)",
            "Course ID": "c711c034f0bc67bf5e6d1a6f03e1bde3"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Customer Service",
            "Course title": "Develop Customer Service Skills (B2)",
            "Course ID": "f05d7da01d222389e4ab229595d31a4b"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "Customer Service",
            "Course title": "Develop Customer Service Skills (C1)",
            "Course ID": "a8d2c5913c2014404aeb11b49aced3e8"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "Customer Service",
            "Course title": "Developing and Managing Relationships with Clients (B1)",
            "Course ID": "55e2fc9ced719c74cb3e3e7c2b2b908a"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Customer Service",
            "Course title": "Developing and Managing Relationships with Clients (B2)",
            "Course ID": "55f52a57466ea1ee158fbd691d1ae966"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "Emergency Services",
            "Course title": "Build English Skills for Emergency Services (B1)",
            "Course ID": "feff7e4a0b07f263f60af419dac2be9e"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Emergency Services",
            "Course title": "Build English Skills for Emergency Services (B2)",
            "Course ID": "558e62dec5fef2e4fdf4e941a76d52fc"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "Emergency Services",
            "Course title": "Build English Skills for Emergency Services (C1)",
            "Course ID": "ace4c3088060f880a35a14d614d99fa7"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "Energy Sector",
            "Course title": "Communicate in the Oil and Gas Industry (B1)",
            "Course ID": "0d48d064cbc373ea3562920f65433d49"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Energy Sector",
            "Course title": "Communicate in the Oil and Gas Industry (B2)",
            "Course ID": "5c7fd43cef47162a15e073b7bdeb1130"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "Energy Sector",
            "Course title": "Communicate in the Oil and Gas Industry (C1)",
            "Course ID": "185c64a587dd202fac52c33ecb6f8e78"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "All Skills (B1)",
            "Course ID": "62ef6e1eddc8dde506bec6039abe6396"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "All Skills (B1)",
            "Course ID": "62ef6e1eddc8dde506bec6039abe6396"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "All Skills (B2)",
            "Course ID": "86415d5fc31ff95e2029cedace677af6"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "All Skills (B2)",
            "Course ID": "86415d5fc31ff95e2029cedace677af6"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "Everyday Situations",
            "Course title": "All Skills (C1)",
            "Course ID": "fd1c3c9c2090478b5f5948854f0b2d1e"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "Everyday Situations",
            "Course title": "All Skills (C1)",
            "Course ID": "fd1c3c9c2090478b5f5948854f0b2d1e"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Focused Skill: Grammar (B1)",
            "Course ID": "0db4cfedd38b6f9356f110e3962b632f"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Focused Skill: Grammar (B2)",
            "Course ID": "2ab214ef0c2cd2b310ac4660dded79c1"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "Everyday Situations",
            "Course title": "Focused Skill: Grammar (C1)",
            "Course ID": "a5af1d20dcf002210443cccd0c097fb2"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Focused Skill: Vocabulary (B1)",
            "Course ID": "591613577a75de0d29c58d2d4b146067"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Focused Skill: Vocabulary (B2)",
            "Course ID": "48ba39db65de58524cd21cd4e6bb4345"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "Everyday Situations",
            "Course title": "Focused Skill: Vocabulary (C1)",
            "Course ID": "693378bcb4f1834b6070baeee92a7f87"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Focused Skills: Reading & Writing (B1)",
            "Course ID": "b43980c0f1e34371c4f2232f28e13247"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Focused Skills: Reading & Writing (B2)",
            "Course ID": "20f3139bcf7fce50da76f0f9dd0f2004"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "Everyday Situations",
            "Course title": "Focused Skills: Reading & Writing (C1)",
            "Course ID": "eb2fb66c9f3e7577a6f3904389042986"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Focused Skills: Speaking & Listening (B1)",
            "Course ID": "3d0f1aa81a66109b6c4e31b63c949578"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Focused Skills: Speaking & Listening (B2)",
            "Course ID": "c5b618406650c6b84ecc21beabf6dd0c"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "Everyday Situations",
            "Course title": "Focused Skills: Speaking & Listening (C1)",
            "Course ID": "c2e09e9eef9d2151f588f5ab508323b4"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "Finance and Insurance",
            "Course title": "Understanding and Analyzing Financial Information (C1)",
            "Course ID": "7b2c2b2c56fc7927dea64d7ac3ec7c47"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Finance and Insurance",
            "Course title": "Understanding Banking Services (B2)",
            "Course ID": "1f5f4ad3fb3532b3342cb5f72d8c2625"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "General Business Communication",
            "Course title": "Improve Business Communication Skills (B1)",
            "Course ID": "5a73b57a739a604cdc6c848802bcf7f7"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "General Business Communication",
            "Course title": "Improve Business Communication Skills (B2)",
            "Course ID": "2225856a6c0a59b2e65ec40c5aeadf9f"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "General Business Communication",
            "Course title": "Improve Business Communication Skills (C1)",
            "Course ID": "bc21c2c29aa378524f27b41a8dd00be0"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "General Business Communication",
            "Course title": "Planning and Organizing Flows (C1)",
            "Course ID": "2fb7979c484e0dc47f7325f9a51eb715"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "General Business Communication",
            "Course title": "Write Effective Email and Business Letters (B1)",
            "Course ID": "f311d6202d13ea7c0d381192f0b32635"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "General Business Communication",
            "Course title": "Write Effective Email and Business Letters (B2)",
            "Course ID": "1d04cb883e596c08057a29d1d3456c38"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "General Business Communication",
            "Course title": "Write Effective Email and Business Letters (C1)",
            "Course ID": "ecb75c1dc6a77e3e6c1fd428fec805d1"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1,B2,C1",
            "Course topic": "Government",
            "Course title": "Video Lessons: Politics and the Economy",
            "Course ID": "7810ac6b77bce7c423ff2e502a003ce5"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "Health and Medicine",
            "Course title": "Communicate Clearly in the Medical Field (B1)",
            "Course ID": "8e592a638232c3b9beebaafa6e70254d"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Health and Medicine",
            "Course title": "Communicate Clearly in the Medical Field (B2)",
            "Course ID": "c2af0c699db105291fc14d48cc217dfa"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "Health and Medicine",
            "Course title": "Communicate Clearly in the Medical Field (C1)",
            "Course ID": "934837b6b2c3340ab427ebea0e05a741"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "Health and Medicine",
            "Course title": "Interact in the Pharmaceutical Industry (B1)",
            "Course ID": "45fea695d7236574cd55e32dcd0af2f9"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Health and Medicine",
            "Course title": "Interact in the Pharmaceutical Industry (B2)",
            "Course ID": "78fc181d1b5ee9ec4e6338e9e259daf7"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "Health and Medicine",
            "Course title": "Interact in the Pharmaceutical Industry (C1)",
            "Course ID": "25b35016f30b11cc17b2575b0bbceb80"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "IT and Telecom",
            "Course title": "Communicate Successfully in the IT Industry (B1)",
            "Course ID": "4a24eb02f7f9c74d3ec09ff80eda4341"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "IT and Telecom",
            "Course title": "Communicate Successfully in the IT Industry (B2)",
            "Course ID": "aaafaa9ae756228e3dbe25056079073e"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "IT and Telecom",
            "Course title": "Communicate Successfully in the IT Industry (C1)",
            "Course ID": "fba335606008b54e69ba54295b5fd5a5"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "IT and Telecom",
            "Course title": "Participate in the Telecom Industry (B1)",
            "Course ID": "cfc417f149b9480a42041f221db750e1"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "IT and Telecom",
            "Course title": "Participate in the Telecom Industry (B2)",
            "Course ID": "68658effeb6f612ec08a32e11c3c6679"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "IT and Telecom",
            "Course title": "Participate in the Telecom Industry (C1)",
            "Course ID": "f67fc06f06413a6a4bc712029ded0df3"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "Manufacturing and Logistics",
            "Course title": "Talk about Safety in Manufacturing (B1)",
            "Course ID": "52713ab3abfec827244a9339d54a91b0"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Manufacturing and Logistics",
            "Course title": "Talk about Safety in Manufacturing (B2)",
            "Course ID": "a7c2a4d55e8f303fad5491b403995101"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "Manufacturing and Logistics",
            "Course title": "Talk about Safety in Manufacturing (C1)",
            "Course ID": "c2f375eb8d60547ed03b2de2e73d0e81"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "On the Phone",
            "Course title": "Making Professional Phone Calls (B1)",
            "Course ID": "a3e45d4abd6c9b18776f5952859451a9"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "On the Phone",
            "Course title": "Succeed on Phone and Conference Calls (B1)",
            "Course ID": "82725235ed33ff3369cbda4d88c68bd1"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "On the Phone",
            "Course title": "Succeed on Phone and Conference Calls (B2)",
            "Course ID": "45cf1eb338aabee9e8c061f61227d9c3"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "On the Phone",
            "Course title": "Succeed on Phone and Conference Calls (C1)",
            "Course ID": "a52a8dbc231ed4c1281482579d8179dd"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "People Management",
            "Course title": "Manage and Lead Teams (B1)",
            "Course ID": "041a26f1e5ea2dfcc61c2d0479508216"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "People Management",
            "Course title": "Manage and Lead Teams (B2)",
            "Course ID": "e95a2f225eac1f8b2031f7f619a97943"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "People Management",
            "Course title": "Manage and Lead Teams (C1)",
            "Course ID": "f002698895b2a96cb0fead580add8aa1"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "Reports and Presentations",
            "Course title": "Presenting and Discussing a Product (B1)",
            "Course ID": "111e4d3f96859003d170dc1899ebae59"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Reports and Presentations",
            "Course title": "Presenting and Discussing a Product (B2)",
            "Course ID": "c48cbbc33bf377d78df010c628b5579f"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "Reports and Presentations",
            "Course title": "Understand and Give Presentations (B1)",
            "Course ID": "6241865ce0e2db03081565aceeec0746"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Reports and Presentations",
            "Course title": "Understand and Give Presentations (B2)",
            "Course ID": "a0e2ef0d350b2dc7ff248c3f7a208919"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "Reports and Presentations",
            "Course title": "Understand and Give Presentations (C1)",
            "Course ID": "74e1801f7bebdf67d67577b597d484d2"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "Reports and Presentations",
            "Course title": "Write and Understand Reports (B1)",
            "Course ID": "17f85aeeee26783efa4472d55f3faed0"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Reports and Presentations",
            "Course title": "Write and Understand Reports (B2)",
            "Course ID": "1ee7e7f74166118b2949c873d6f801fc"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "Reports and Presentations",
            "Course title": "Write and Understand Reports (C1)",
            "Course ID": "7139d139948953b1ccb11cbd5508a01b"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "Sales and Marketing",
            "Course title": "Conducting Negotiations Involving Contract Law (C1)",
            "Course ID": "af90880d3b035e74a40299aa5927cd0c"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "Sales and Marketing",
            "Course title": "Managing Sales and After-Sales Contacts (B1)",
            "Course ID": "7e7e0041529ae40fc9176c0a26187e42"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1,B2,C1",
            "Course topic": "The Environment and Physical Sciences",
            "Course title": "Video Lessons: Science",
            "Course ID": "87ee9e563ccda3cb40eb30a982de4096"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1,B2,C1",
            "Course topic": "The Environment and Physical Sciences",
            "Course title": "Video Lessons: Space",
            "Course ID": "320b95c5f59a5af6a8e235d6fa81ee7b"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B1",
            "Course topic": "Travel and Hospitality",
            "Course title": "Arrange Travel and Receive Visitors (B1)",
            "Course ID": "debec64008a74478bdb6b2652fca79ee"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Travel and Hospitality",
            "Course title": "Arrange Travel and Receive Visitors (B2)",
            "Course ID": "71ca3a3814e5827cd744c2960d52584d"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "C1",
            "Course topic": "Travel and Hospitality",
            "Course title": "Arrange Travel and Receive Visitors (C1)",
            "Course ID": "eaa2b50c91e6a60a59160a91a2ad38c3"
        },
        {
            "Learning language": "en-GB",
            "CEFR": "B2",
            "Course topic": "Travel and Hospitality",
            "Course title": "Organizing Business Trips (B2)",
            "Course ID": "b396338ca8ab76d4b6a0e3a5cbe3c7fa"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Administrative Tasks",
            "Course title": "Administrative & Secretarial",
            "Course ID": "a19d75509386a09a787b2cf6ba67fba5"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Architecture and Construction",
            "Course title": "Architecture & the Building Industry",
            "Course ID": "3251bb09b7f07430f764107c790ada45"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Architecture and Construction",
            "Course title": "Building & Construction",
            "Course ID": "e15a48230e25dd1553d8e206551ca907"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Automotive",
            "Course title": "Automotive Industry",
            "Course ID": "445a96943b86774f1d79cbb5dd502ed1"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Automotive",
            "Course title": "Automotive Industry",
            "Course ID": "445a96943b86774f1d79cbb5dd502ed1"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Aviation",
            "Course title": "Airlines & Aerospace Part 1",
            "Course ID": "082073dbe1710b317aa7e35e1b171c59"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Aviation",
            "Course title": "Airlines & Aerospace Part 2",
            "Course ID": "9f2cab8389ad9252ff625c5093335887"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Aviation",
            "Course title": "Airlines & Aerospace Part 3",
            "Course ID": "db93437246e748647fc036992bcf954f"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Aviation",
            "Course title": "Airlines & Aerospace Part 4",
            "Course ID": "3a0ea6f41d9ae319af10ea8d5bc50ac0"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Aviation",
            "Course title": "Airlines & Aerospace Part 5",
            "Course ID": "d022e7367e833cf37243f070a7cc28a2"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "Aviation",
            "Course title": "Speak with Pilots and Airline Mechanics (B1)",
            "Course ID": "c11c7c3947eba838810a81f62b736b9f"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "Aviation",
            "Course title": "Speak with Pilots and Airline Mechanics (B2)",
            "Course ID": "a739732ebfb4d6cdbc8d514d4ff9bdc9"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "Aviation",
            "Course title": "Speak with Pilots and Airline Mechanics (C1)",
            "Course ID": "bd7465cc88c46167ca8444a2b9ef4fbd"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "Business Meetings",
            "Course title": "Lead, Communicate, and Listen in Meetings (B1)",
            "Course ID": "3cb1ba1098a7726c0ecd19cc1823f40b"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "Business Meetings",
            "Course title": "Lead, Communicate, and Listen in Meetings (B2)",
            "Course ID": "2ef11ba611d53dfdbf4e739c2f0284fa"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "Business Meetings",
            "Course title": "Lead, Communicate, and Listen in Meetings (C1)",
            "Course ID": "bc84c0820f1d9bde0b4f0293c1a6e1a5"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "Business Meetings",
            "Course title": "Leading Meetings and Presentations (B1)",
            "Course ID": "d5ff4823b4d770eab43f47fd7e1fb139"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "Business Meetings",
            "Course title": "Leading Meetings and Presentations (B2)",
            "Course ID": "815f7886e37561953d0bff357263354b"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "Business Meetings",
            "Course title": "Leading Meetings and Presentations (C1)",
            "Course ID": "7d37a62fdb96181a19b1de86a59f5438"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "Business Meetings",
            "Course title": "Leading Meetings and Presentations (C1)",
            "Course ID": "7d37a62fdb96181a19b1de86a59f5438"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "Business Meetings",
            "Course title": "Typical Business and Employment Interactions (C1)",
            "Course ID": "e6b40840ca4ce04438dfd4265eebee5a"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "Business Meetings",
            "Course title": "Typical Business and Employment Interactions (C1)",
            "Course ID": "e6b40840ca4ce04438dfd4265eebee5a"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "Career and Employment",
            "Course title": "Manage Your Career (B1)",
            "Course ID": "0437b7376fd999e9fa42e8e746047591"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "Career and Employment",
            "Course title": "Manage Your Career (B2)",
            "Course ID": "64ffaea50d369bb7e444a161eb05f05c"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "Career and Employment",
            "Course title": "Manage Your Career (C1)",
            "Course ID": "1302b95013e8e9aaf5b72dd6e9228b46"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "Culture",
            "Course title": "Cultural Exercises (B1)",
            "Course ID": "bce9d216-f197-46d8-55c2-8075b326ebcc"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "Culture",
            "Course title": "Cultural Exercises (B2)",
            "Course ID": "a7bab09d-c1fe-e12a-396e-d35bef29ebea"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Culture",
            "Course title": "Video Lesson: Culture in the United States",
            "Course ID": "82fca0f047442a6a6f3f687becdbb8bc"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Customer Service",
            "Course title": "After-Sales & Customer Service",
            "Course ID": "eaea71b058c74dcda2c18bc47a15f2b1"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Customer Service",
            "Course title": "After-Sales & Customer Service",
            "Course ID": "eaea71b058c74dcda2c18bc47a15f2b1"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "Customer Service",
            "Course title": "Develop Customer Service Skills (B1)",
            "Course ID": "9e16e999a37b37b284fd9364b47c09d0"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "Customer Service",
            "Course title": "Develop Customer Service Skills (B2)",
            "Course ID": "d8eda5ff944577fb650545d3bfc897ac"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "Customer Service",
            "Course title": "Develop Customer Service Skills (C1)",
            "Course ID": "71f2f57ab9e64ca3cc58d7016ce7dae5"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Education",
            "Course title": "Education",
            "Course ID": "e11bfa6566759c427cd3637949030a43"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "Emergency Services",
            "Course title": "Build English Skills for Emergency Services (B1)",
            "Course ID": "578aca1df053589073df537ff0fd626a"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "Emergency Services",
            "Course title": "Build English Skills for Emergency Services (B2)",
            "Course ID": "c84e3db7966a9583472c3957d4761aab"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "Emergency Services",
            "Course title": "Build English Skills for Emergency Services (C1)",
            "Course ID": "32c0b00d11e695a12fc35c22f31103e2"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "Energy Sector",
            "Course title": "Communicate in the Oil and Gas Industry (B2)",
            "Course ID": "e8ac167a19b7f3c2ba35568fa86d06cd"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Energy Sector",
            "Course title": "Energy & Fuel",
            "Course ID": "c40b46ae7727d73b492caea462b8de15"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "Engineering",
            "Course title": "Technical Communication for Electrical Engineers (B1)",
            "Course ID": "e19295fb-2c50-4c9e-9674-4c379a463083"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2,C1",
            "Course topic": "Engineering",
            "Course title": "Technical Communication for Electrical Engineers (B2-C1)",
            "Course ID": "21993c36-dffa-4458-9a1c-76d3587facce"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Ethics",
            "Course title": "Business Ethics Part 1",
            "Course ID": "cac5cef2145f8a1b9bff7401eaafc2e2"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Ethics",
            "Course title": "Business Ethics Part 2",
            "Course ID": "38b2b242b3a8c9cfa409a0aa68baba97"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "All Skills (B1)",
            "Course ID": "bba24d6b05441b6e4675959c8276a286"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "All Skills (B2)",
            "Course ID": "0ff0bf2c342e7aadbd4b45f4261239cc"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Focused Skill: Grammar (B1)",
            "Course ID": "26ccfc167bd578d9eb7bb4253a788a98"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Focused Skill: Grammar (B2)",
            "Course ID": "bbda3627bb73524585406ed3125db76a"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Focused Skill: Vocabulary (B1)",
            "Course ID": "b18031aba8edb8d5d224200301fbff3a"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Focused Skill: Vocabulary (B2)",
            "Course ID": "b077369e6ad9500342a1c24b5ed30867"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Focused Skills: Reading & Writing (B1)",
            "Course ID": "a24ab06d55e4da0679c34a838d6bda75"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Focused Skills: Reading & Writing (B2)",
            "Course ID": "275e077b7146d3f1f8ff920ceb2cba1c"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Focused Skills: Speaking & Listening (B1)",
            "Course ID": "59482d399eb6aaf17ca6c21d16dc89ac"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Focused Skills: Speaking & Listening (B2)",
            "Course ID": "08c25c76212173290f3d43f614f234f0"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Gaia Demonstration Course (B1)",
            "Course ID": "95876c65-87be-4d52-a1fd-54aee09e91cb"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Finance and Insurance",
            "Course title": "Accounting & Finance",
            "Course ID": "4afd4f6ebc2a3b8116c22375de2e5c95"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Finance and Insurance",
            "Course title": "Accounting & Finance",
            "Course ID": "4afd4f6ebc2a3b8116c22375de2e5c95"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Finance and Insurance",
            "Course title": "Banking & Finance",
            "Course ID": "bcbe668c9a95e61b2d5b9a2387fe53c0"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Finance and Insurance",
            "Course title": "Insurance & Financial Services Part 1",
            "Course ID": "94ff8c0c1fdb06f7e2e9a2ba762fc92d"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Finance and Insurance",
            "Course title": "Insurance & Financial Services Part 2",
            "Course ID": "c35a8e5b819fe74ef925f399325660af"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Finance and Insurance",
            "Course title": "Insurance & Financial Services Part 2",
            "Course ID": "c35a8e5b819fe74ef925f399325660af"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "General Business Communication",
            "Course title": "Handling Negotiations and Sensitive Topics (B2)",
            "Course ID": "f1ae1934a42268239379e29783fb0d43"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "General Business Communication",
            "Course title": "Handling Negotiations and Sensitive Topics (B2)",
            "Course ID": "f1ae1934a42268239379e29783fb0d43"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "General Business Communication",
            "Course title": "Handling Negotiations and Sensitive Topics (C1)",
            "Course ID": "1484f14862c87717497e09e90c8f5bf9"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "General Business Communication",
            "Course title": "Handling Negotiations and Sensitive Topics (C1)",
            "Course ID": "1484f14862c87717497e09e90c8f5bf9"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "General Business Communication",
            "Course title": "Improve Business Communication Skills (B1)",
            "Course ID": "f83d23f2ad3fb92e7c5ec22aa6d9d7bc"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "General Business Communication",
            "Course title": "Improve Business Communication Skills (B2)",
            "Course ID": "9910168858f78a4eb646dd541b1362cf"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "General Business Communication",
            "Course title": "Improve Business Communication Skills (C1)",
            "Course ID": "3ed52b69e7a3a8b6ba74b5024aec0d1b"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "General Business Communication",
            "Course title": "Talking with Clients, Customers, and Partners (B1)",
            "Course ID": "56754188eb6307b9d2770cba60a034b0"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "General Business Communication",
            "Course title": "Talking with Clients, Customers, and Partners (B1)",
            "Course ID": "56754188eb6307b9d2770cba60a034b0"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "General Business Communication",
            "Course title": "Talking with Clients, Customers, and Partners (B2)",
            "Course ID": "876d90cd49eee120110436bd8af8db36"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "General Business Communication",
            "Course title": "Talking with Clients, Customers, and Partners (C1)",
            "Course ID": "c3305a5b78524a6f72a9291e2c1b864b"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "General Business Communication",
            "Course title": "Talking with Clients, Customers, and Partners (C1)",
            "Course ID": "c3305a5b78524a6f72a9291e2c1b864b"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "General Business Communication",
            "Course title": "Typical Business and Employment Interactions (B1)",
            "Course ID": "aefc3bf647e0c78b1f6ffce3415c61ca"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "General Business Communication",
            "Course title": "Write Effective E-mail and Business Letters (B1)",
            "Course ID": "87d4cb21c21756b43c6d47ed3908b179"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "General Business Communication",
            "Course title": "Write Effective E-mail and Business Letters (B2)",
            "Course ID": "253285758019d43286a3ed1f2cc1adf5"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "General Business Communication",
            "Course title": "Write Effective E-mail and Business Letters (C1)",
            "Course ID": "45982d6446eae753e70c2c6bc7ffb3c8"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Government",
            "Course title": "Government & the Public Sector",
            "Course ID": "639ec4ad6b2f39ec2eda9acdf3c89276"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Government",
            "Course title": "State and Local Government",
            "Course ID": "adbf783655c3b779832c0aefc98055c8"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "Health and Medicine",
            "Course title": "Communicate Clearly in the Medical Field (B1)",
            "Course ID": "67340063a979956450f843a97929c892"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "Health and Medicine",
            "Course title": "Communicate Clearly in the Medical Field (B2)",
            "Course ID": "254e4f2e376661e148281755d6dd70fe"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "Health and Medicine",
            "Course title": "Communicate Clearly in the Medical Field (C1)",
            "Course ID": "08994657b3ea8dc69e1986d0394b00f6"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Healthcare & Pharmaceuticals Part 1",
            "Course ID": "56c6aeadcbd3f9a6326e699fbad21e84"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Healthcare & Pharmaceuticals Part 2",
            "Course ID": "183636a6018774e5246c50271049d1e6"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Healthcare & Pharmaceuticals Part 2",
            "Course ID": "183636a6018774e5246c50271049d1e6"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Healthcare & Pharmaceuticals Part 3",
            "Course ID": "3265ff16057194d934996b60148353de"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Healthcare & Pharmaceuticals Part 4",
            "Course ID": "634d250abca557a4443e45efa53d713a"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Humanitarian Medicine",
            "Course ID": "d8211b3bd9b588205eda6396193a57da"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "Health and Medicine",
            "Course title": "Interact in the Pharmaceutical Industry (B1)",
            "Course ID": "32d031d91936d3f9b166ca811a4acdb6"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "Health and Medicine",
            "Course title": "Interact in the Pharmaceutical Industry (B2)",
            "Course ID": "9f7081c651df2ae7543d3ad8bb35a754"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "Health and Medicine",
            "Course title": "Interact in the Pharmaceutical Industry (C1)",
            "Course ID": "770b3119d2b4ede146a40e4fa3af3298"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Medicine & Health Part 1",
            "Course ID": "04a28e2054333120a70fc26bba90fbc7"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Medicine & Health Part 2",
            "Course ID": "203aac268dbf2523a2b36de62cbab386"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Medicine & Health Part 3",
            "Course ID": "b9808fb83fcaf3a6e8d3b2e1d564c19a"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Medicine & Health Part 4",
            "Course ID": "b7ea5050d0ea2a4e459a6c52a7e38340"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Human Resources",
            "Course title": "Human Resources",
            "Course ID": "bf6c6591ca74e9fe9e63ed49ed3692f0"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Human Resources",
            "Course title": "Human Resources",
            "Course ID": "bf6c6591ca74e9fe9e63ed49ed3692f0"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "IT and Telecom",
            "Course title": "Communicate Successfully in the IT Industry (B1)",
            "Course ID": "042ed37687071d81bb321b512d881916"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "IT and Telecom",
            "Course title": "Communicate Successfully in the IT Industry (B2)",
            "Course ID": "154ce2d14b0c980eb3fd7dd6a6b41b80"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "IT and Telecom",
            "Course title": "Communicate Successfully in the IT Industry (C1)",
            "Course ID": "5c501f61280be83ba788798039e651d0"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "IT and Telecom",
            "Course title": "IT & Telecom",
            "Course ID": "31008c696e34964cc9233a2993ee13c6"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "IT and Telecom",
            "Course title": "IT Part 1",
            "Course ID": "01086416547dd3c43bbd990877e37308"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "IT and Telecom",
            "Course title": "IT Part 2",
            "Course ID": "5716f918f5f49b48e2e003152a9bafd4"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "IT and Telecom",
            "Course title": "IT Part 2",
            "Course ID": "5716f918f5f49b48e2e003152a9bafd4"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "IT and Telecom",
            "Course title": "Participate in the Telecom Industry (B1)",
            "Course ID": "9b9ec8a010478150beeae81a576168e0"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "IT and Telecom",
            "Course title": "Participate in the Telecom Industry (B2)",
            "Course ID": "15546e3d0a91e34195df32749089fa3d"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "IT and Telecom",
            "Course title": "Participate in the Telecom Industry (C1)",
            "Course ID": "765943a66929f3851691cd71bb5edc34"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Law Enforcement and Military",
            "Course title": "Law Enforcement",
            "Course ID": "e4aa22f4643b26f2aba370f9750db8bc"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Law Enforcement and Military",
            "Course title": "Military & Defense Part 1",
            "Course ID": "5aadf490d80d365fb9cf0271032f3cc1"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Law Enforcement and Military",
            "Course title": "Military & Defense Part 2",
            "Course ID": "2a5544bee094c0f42bf906b975b50d6d"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Law Enforcement and Military",
            "Course title": "Security",
            "Course ID": "553fefad0c17bb4e10c96738239f532b"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Legal",
            "Course title": "Law & Justice",
            "Course ID": "22886068822c0e74d94fb01ebcfe5619"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Legal",
            "Course title": "Legal",
            "Course ID": "8fd1e314378a72505af5c5fd1d7e86a4"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Legal",
            "Course title": "Legal",
            "Course ID": "8fd1e314378a72505af5c5fd1d7e86a4"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Manufacturing and Logistics",
            "Course title": "Industry, Manufacturing, & Logistics",
            "Course ID": "c9359775a801920d63f2584ab4b5d15f"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "Manufacturing and Logistics",
            "Course title": "Talk about Safety in Manufacturing (B1)",
            "Course ID": "e8ec0a16bc43d1f756b5f473b5058c66"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "Manufacturing and Logistics",
            "Course title": "Talk about Safety in Manufacturing (B2)",
            "Course ID": "646e44cffb6f762e0fffc5a6b9bd3ea5"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "Manufacturing and Logistics",
            "Course title": "Talk about Safety in Manufacturing (C1)",
            "Course ID": "e52dcdb3bfe0aacd92e2b969f1026df4"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "On the Phone",
            "Course title": "Leading Telephone Conversations (B1)",
            "Course ID": "1a65dff89e287063228e3fa7955229fc"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "On the Phone",
            "Course title": "Succeed on Phone and Conference Calls (B1)",
            "Course ID": "d6a7be9b7e952803d794bc0f49b13759"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "On the Phone",
            "Course title": "Succeed on Phone and Conference Calls (B2)",
            "Course ID": "93fd64ad171b6181d81e5015fdb4cdd8"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "On the Phone",
            "Course title": "Succeed on Phone and Conference Calls (C1)",
            "Course ID": "6cb47850b2e611640c0f0f6460d424cf"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "People Management",
            "Course title": "Manage and Lead Teams (B1)",
            "Course ID": "3f927d957b353971d5b05cd65dd6bd0d"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "People Management",
            "Course title": "Manage and Lead Teams (B2)",
            "Course ID": "1744684ea14feef8210a49567d60044f"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "People Management",
            "Course title": "Manage and Lead Teams (C1)",
            "Course ID": "59af402130df60104846f0fe5ec46a8c"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "Reading Skills",
            "Course title": "Read for Professional Purposes (B1)",
            "Course ID": "0fa701dbe6ab8a9269ec47b34b39ddf9"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "Reading Skills",
            "Course title": "Read for Professional Purposes (B2)",
            "Course ID": "a9a4c83ca499b27562803007c1d6b04b"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "Reading Skills",
            "Course title": "Read for Professional Purposes (C1)",
            "Course ID": "b988aaaaf6d6e5876fc10b2263eeef46"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "Reports and Presentations",
            "Course title": "Understand and Give Presentations (B1)",
            "Course ID": "bccb4d4f6acaa19b80008389d31fe6bd"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "Reports and Presentations",
            "Course title": "Understand and Give Presentations (B2)",
            "Course ID": "a2483a8d5dd844e4881efcf8dc3d0362"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "Reports and Presentations",
            "Course title": "Understand and Give Presentations (C1)",
            "Course ID": "5e939f1d37a2a9a01145370cdac2d9b6"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "Reports and Presentations",
            "Course title": "Write and Understand Reports (B1)",
            "Course ID": "470d2724c1292913ffd02e8f88b6d00c"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "Reports and Presentations",
            "Course title": "Write and Understand Reports (B2)",
            "Course ID": "7fda1f4444670b3eab28dd11ec948cee"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "Reports and Presentations",
            "Course title": "Write and Understand Reports (C1)",
            "Course ID": "97a734d78f19d748dab9ab5c6a5fd7b9"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Sales and Marketing",
            "Course title": "Marketing & the Advertising Industry",
            "Course ID": "f0f2c421803c22e90904c8a1cc16b67f"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Sales and Marketing",
            "Course title": "Sales, Marketing, & Advertising",
            "Course ID": "6fdff547f73a937110339a36444ea172"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Sales and Marketing",
            "Course title": "Sales, Marketing, & Advertising",
            "Course ID": "6fdff547f73a937110339a36444ea172"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "The Environment and Physical Sciences",
            "Course title": "Communicate in the Oil and Gas Industry (B1)",
            "Course ID": "78bf925fadb4f4ba898cf6c727e6cd6e"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "The Environment and Physical Sciences",
            "Course title": "Communicate in the Oil and Gas Industry (C1)",
            "Course ID": "ada15f6ee9cee0e16776fcf0187915e4"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "The Environment and Physical Sciences",
            "Course title": "Environment & Meterology Part 1",
            "Course ID": "0b6d2101f586daf269912df9a71c0d66"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "The Environment and Physical Sciences",
            "Course title": "Environment & Meterology Part 2",
            "Course ID": "3d34fffc0f7d63a4859e4dba8f904ebd"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "The Environment and Physical Sciences",
            "Course title": "Environment & Meterology Part 3",
            "Course ID": "b004709e9a30ca76c5e335d662c1a86b"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "The Environment and Physical Sciences",
            "Course title": "The Natural Sciences",
            "Course ID": "fab2a0483fcdfac57e277dab8a05163f"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1",
            "Course topic": "Travel and Hospitality",
            "Course title": "Arrange Travel and Receive Visitors (B1)",
            "Course ID": "ac5ef2b9e6a4f110582c2008f356852f"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B2",
            "Course topic": "Travel and Hospitality",
            "Course title": "Arrange Travel and Receive Visitors (B2)",
            "Course ID": "56067e244845244c712cc42320a7f1fa"
        },
        {
            "Learning language": "en-US",
            "CEFR": "C1",
            "Course topic": "Travel and Hospitality",
            "Course title": "Arrange Travel and Receive Visitors (C1)",
            "Course ID": "2db843d62087cef98ca2f2556d14a12b"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Travel and Hospitality",
            "Course title": "The Airport",
            "Course ID": "5587e16f5e21a918e2d550e494b5eb14"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Travel and Hospitality",
            "Course title": "Travel & Hospitality Part 1",
            "Course ID": "3565abb972964bc77c2757bb15470cc1"
        },
        {
            "Learning language": "en-US",
            "CEFR": "B1,B2,C1",
            "Course topic": "Travel and Hospitality",
            "Course title": "Travel & Hospitality Part 2",
            "Course ID": "30e353af268668f9ad9e7409005b396d"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B1",
            "Course topic": "Culture",
            "Course title": "Talleres culturales (B1)",
            "Course ID": "5d016473-7eec-5cd6-94c5-bf6062f85b10"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B2",
            "Course topic": "Culture",
            "Course title": "Talleres culturales (B2)",
            "Course ID": "062db031-4dac-1bc4-9b6a-27be09e3808e"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "C1",
            "Course topic": "Culture",
            "Course title": "Talleres culturales (C1)",
            "Course ID": "bee49ec9-c86c-5969-8d3f-0f80cb063dae"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B1,B2,C1",
            "Course topic": "Culture",
            "Course title": "Vídeos: Cultura y sociedad",
            "Course ID": "d3ff3ff1a926e935389b72fc414a9f4d"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B1,B2,C1",
            "Course topic": "Culture",
            "Course title": "Vídeos: Política y economía",
            "Course ID": "289338ad925c88b30e3626797551dc79"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B1",
            "Course topic": "Customer Service",
            "Course title": "Relacionarse con clientes (B1)",
            "Course ID": "9f2fc3563d91f039b6b9af757be9e655"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B2",
            "Course topic": "Customer Service",
            "Course title": "Relacionarse con clientes (B2)",
            "Course ID": "c36a43a7efb9f781e2c6c49f7279109a"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Competencia de enfoque: Gramática (B1)",
            "Course ID": "83762832ec70b621d8701b03c6c5844b"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Competencia de enfoque: Gramática (B2)",
            "Course ID": "dc837cbba12f3e9a1f52dc6fab750c13"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "C1",
            "Course topic": "Everyday Situations",
            "Course title": "Competencia de enfoque: Gramática (C1)",
            "Course ID": "5a5b162151ecab440d0ffd90fdd14a6c"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Competencia de enfoque: Vocabulario (B1)",
            "Course ID": "8d1fbfb6efc5f091da9d3dbe40c90950"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Competencia de enfoque: Vocabulario (B2)",
            "Course ID": "467548edc23e4665c76d80a2a7bfe3d9"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "C1",
            "Course topic": "Everyday Situations",
            "Course title": "Competencia de enfoque: Vocabulario (C1)",
            "Course ID": "83d2fd6e0f072d4bed632ae3748e5cae"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Competencias de enfoque: Comprensión y expresión escrita (B1)",
            "Course ID": "8f7be957a401c37ea851500e2fed4d60"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Competencias de enfoque: Comprensión y expresión escrita (B2)",
            "Course ID": "20f30191cb54ff27b81bb5ff6aaab295"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "C1",
            "Course topic": "Everyday Situations",
            "Course title": "Competencias de enfoque: Comprensión y expresión escrita (C1)",
            "Course ID": "92ce7e5fa721a703702fc7e2a197a186"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Competencias de enfoque: Comprensión y expresión oral (B1)",
            "Course ID": "f6359b8d2301968ef7cdaba5b4f6b527"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Competencias de enfoque: Comprensión y expresión oral (B2)",
            "Course ID": "44c01d4a64c938c0984574c11ae62740"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "C1",
            "Course topic": "Everyday Situations",
            "Course title": "Competencias de enfoque: Comprensión y expresión oral (C1)",
            "Course ID": "ba42123d07aba837aec0c9e1e520ab88"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Todas las competencias (B1)",
            "Course ID": "eaef3ec8c3e01f148b721f555a3c0435"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Todas las competencias (B2)",
            "Course ID": "f9489fd2c4f257e100388e6ff114b7ef"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "C1",
            "Course topic": "Everyday Situations",
            "Course title": "Todas las competencias (C1)",
            "Course ID": "5bb4a59c64ea9b926b959ebb129fb6ba"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B2",
            "Course topic": "Finance and Insurance",
            "Course title": "Asuntos bancarios",
            "Course ID": "d4308044ccc4cbf3eeac3256c306d408"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "C1",
            "Course topic": "Finance and Insurance",
            "Course title": "Comprender y analizar datos financieros",
            "Course ID": "f5d0049e4245f539cb955cc61602075c"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Vídeos: La salud y la medicina",
            "Course ID": "55d86918-4a24-bab5-8f50-66fadbb353e6"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "C1",
            "Course topic": "Manufacturing and Logistics",
            "Course title": "Envío y transporte comercial",
            "Course ID": "331f39cc2adabd07e29bde558f181841"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B1",
            "Course topic": "Sales and Marketing",
            "Course title": "El salón",
            "Course ID": "b52b39ee-7426-7414-75c3-7c0d06582f4d"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B1",
            "Course topic": "Sales and Marketing",
            "Course title": "Gestión de relaciones comerciales",
            "Course ID": "43f5cf8f4a2266f966f5770130167818"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B1",
            "Course topic": "Sales and Marketing",
            "Course title": "Gestión de relaciones comerciales",
            "Course ID": "ccf44eb121aa6bf8da1a922a33dd6252"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "C1",
            "Course topic": "Sales and Marketing",
            "Course title": "La conferencia",
            "Course ID": "db436b246aa1f3b4327e4702199c1a95"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "C1",
            "Course topic": "Sales and Marketing",
            "Course title": "Negociar un contrato",
            "Course ID": "3f04598be650a450c0176484bbd20c50"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B1,B2,C1",
            "Course topic": "Technology",
            "Course title": "Vídeos: La tecnología",
            "Course ID": "2eb99b12-31e9-c9bd-1a66-9a02d9d895c9"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B1,B2,C1",
            "Course topic": "The Environment and Physical Sciences",
            "Course title": "Vídeos: Ciencia",
            "Course ID": "02d6f524bacddb55ad241f41d7f66966"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B1,B2,C1",
            "Course topic": "The Environment and Physical Sciences",
            "Course title": "Vídeos: El espacio",
            "Course ID": "2a04b22d44d96c68f912128c6d5f6426"
        },
        {
            "Learning language": "es-ES",
            "CEFR": "B2",
            "Course topic": "Travel and Hospitality",
            "Course title": "Organizar viajes de negocios",
            "Course ID": "0eaa009485ffc012600c31053bc92a5d"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "C1",
            "Course topic": "Administrative Tasks",
            "Course title": "La Marchandise",
            "Course ID": "174f5e6b3e39638ecfb84a532e76c1c2"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B1",
            "Course topic": "Administrative Tasks",
            "Course title": "Service comptable",
            "Course ID": "6f88ce7424891ce4a3173d6fc87506c5"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "C1",
            "Course topic": "Business Meetings",
            "Course title": "Négocier un contrat",
            "Course ID": "d8f33f88a52d72e1f86614622be42956"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B1",
            "Course topic": "Culture",
            "Course title": "Activités culturelles (B1)",
            "Course ID": "dc6dc621-c324-40ec-1f9e-1c852e8fc225"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B2",
            "Course topic": "Culture",
            "Course title": "Activités culturelles (B2)",
            "Course ID": "8895dca5-28c5-c995-ec3d-12d2f2d86ccf"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "C1",
            "Course topic": "Culture",
            "Course title": "Activités culturelles (C1)",
            "Course ID": "cf03c841-8b0d-737a-bfa4-dbbc22b1cf22"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B1,B2,C1",
            "Course topic": "Culture",
            "Course title": "Leçons vidéos : La culture et la société",
            "Course ID": "0f51efd0bd4974e0377b42dab3b4fdd7"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B1",
            "Course topic": "Customer Service",
            "Course title": "Service après-vente",
            "Course ID": "bc5c043f36b7130d03ee32702269d189"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B2",
            "Course topic": "Customer Service",
            "Course title": "Service clientèle",
            "Course ID": "8d6f37f38813cb33c75450edf30026a0"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Compétence visée : Grammaire (B1)",
            "Course ID": "9383e0f71f488917ba205c373ef4181b"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Compétence visée : Grammaire (B2)",
            "Course ID": "7bebf73914d29a4062603268f092b65e"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "C1",
            "Course topic": "Everyday Situations",
            "Course title": "Compétence visée : Grammaire (C1)",
            "Course ID": "268a44a74ed50ffcce01e67c4b351ee2"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Compétence visée : Vocabulaire (B1)",
            "Course ID": "a1dde537863d96d28f96f52d92e1baa5"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Compétence visée : Vocabulaire (B2)",
            "Course ID": "4e3ff4db322ba52aeb6b560db4983188"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "C1",
            "Course topic": "Everyday Situations",
            "Course title": "Compétence visée : Vocabulaire (C1)",
            "Course ID": "1827b3c274f149fc4cfd05ad0a0a2bc0"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Compétences visées : Compréhension et expression écrites (B2)",
            "Course ID": "0d1993f8d2ae7230c2a6ca279860b21c"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "C1",
            "Course topic": "Everyday Situations",
            "Course title": "Compétences visées : Compréhension et expression écrites (C1)",
            "Course ID": "2dc5c600aef996a8df08f86b03c8a45d"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Compétences visées : Compréhension et expression orales (B2)",
            "Course ID": "927ea3a66160a9d6baf03235f0808b72"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "C1",
            "Course topic": "Everyday Situations",
            "Course title": "Compétences visées : Compréhension et expression orales (C1)",
            "Course ID": "e9f6e211f48cb503a85a0085557cb81c"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Compétences visées : Écouter et parler (B1)",
            "Course ID": "1ed40937c73dc820f295fb66bba26cf0"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Compétences visées : Lire et écrire (B1)",
            "Course ID": "f28a2db040372af60034d599e1751147"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Toutes les compétences (B1)",
            "Course ID": "effc5f634e8f344fbc37f51b0de90277"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B2",
            "Course topic": "Everyday Situations",
            "Course title": "Toutes les compétences (B2)",
            "Course ID": "25189f8e922e1b641962c4d036845f85"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "C1",
            "Course topic": "Everyday Situations",
            "Course title": "Toutes les compétences (C1)",
            "Course ID": "a3431a7e248c89e223cee6d402a529bf"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "C1",
            "Course topic": "Finance and Insurance",
            "Course title": "La Bourse",
            "Course ID": "377a6186ed4703fd7e0a7985ad33a480"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B1",
            "Course topic": "General Business Communication",
            "Course title": "Communications pratiques (B1)",
            "Course ID": "c5b4465b1732e6cb3825831c91505a37"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B2",
            "Course topic": "General Business Communication",
            "Course title": "Communications pratiques (B2) ",
            "Course ID": "7f09b300c80fb16d39ff83b59cc0e998"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "C1",
            "Course topic": "General Business Communication",
            "Course title": "Communications pratiques (C1)",
            "Course ID": "a20bdfa1945a65eb4a2a3a86077c76d7"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Leçons vidéos : La santé et la médecine",
            "Course ID": "ba0f7dfb-3419-bf24-df32-e65664323d64"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B1",
            "Course topic": "Sales and Marketing",
            "Course title": "Service des ventes",
            "Course ID": "4e8a4410-546b-d824-5bf2-825e659cc781"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B1,B2,C1",
            "Course topic": "Technology",
            "Course title": "Leçons vidéos : La technologie ",
            "Course ID": "49d7c20f-b7f8-4fb5-23f0-9c17c495178e"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B1,B2,C1",
            "Course topic": "The Environment and Physical Sciences",
            "Course title": "Leçons vidéos : Les sciences",
            "Course ID": "fff5420e1cd4813fc2aa48a633f3f699"
        },
        {
            "Learning language": "fr-FR",
            "CEFR": "B1,B2,C1",
            "Course topic": "The Environment and Physical Sciences",
            "Course title": "Leçons vidéos : l'Espace ",
            "Course ID": "8079008925cad09090982157bc7b1519"
        },
        {
            "Learning language": "it-IT",
            "CEFR": "B1",
            "Course topic": "Culture",
            "Course title": "Attività culturali (B1)",
            "Course ID": "69daca15-713f-35f3-4475-4a0497c20a8e"
        },
        {
            "Learning language": "it-IT",
            "CEFR": "B2,C1",
            "Course topic": "Culture",
            "Course title": "Attività culturali (B2/C1)",
            "Course ID": "9cb52a1c-1909-f109-e7e1-7b4c76918ea7"
        },
        {
            "Learning language": "it-IT",
            "CEFR": "B1,B2,C1",
            "Course topic": "Culture",
            "Course title": "Video: cultura e società",
            "Course ID": "2e377992fa658405094d7ba581d22999"
        },
        {
            "Learning language": "it-IT",
            "CEFR": "B2,C1",
            "Course topic": "Customer Service",
            "Course title": "Gestire le relazioni con i clienti ",
            "Course ID": "816e320824fdca17a90fbc0d0f5e83a5"
        },
        {
            "Learning language": "it-IT",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Competenza specifica: grammatica (B1)",
            "Course ID": "7691b00b50174fc5693b2eae8e68a034"
        },
        {
            "Learning language": "it-IT",
            "CEFR": "B2,C1",
            "Course topic": "Everyday Situations",
            "Course title": "Competenza specifica: grammatica (B2/C1)",
            "Course ID": "a91de8920841007b81666741f58eecb9"
        },
        {
            "Learning language": "it-IT",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Competenza specifica: vocabolario (B1)",
            "Course ID": "0d70223dff7cb6f928ae87194293461f"
        },
        {
            "Learning language": "it-IT",
            "CEFR": "B2,C1",
            "Course topic": "Everyday Situations",
            "Course title": "Competenza specifica: vocabolario (B2/C1)",
            "Course ID": "16dccf9a980aabba8e843011acaf621d"
        },
        {
            "Learning language": "it-IT",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Competenze specifiche: espressione orale e ascolto (B1)",
            "Course ID": "ef1325ed95008cd0368fb89e861e5f30"
        },
        {
            "Learning language": "it-IT",
            "CEFR": "B2,C1",
            "Course topic": "Everyday Situations",
            "Course title": "Competenze specifiche: Espressione orale e ascolto (B2/C1)",
            "Course ID": "3becaf66e6bd3f0b4313967f00174d94"
        },
        {
            "Learning language": "it-IT",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Competenze specifiche: lettura e scrittura (B1)",
            "Course ID": "be3a09eafa224228022931aa4baaf558"
        },
        {
            "Learning language": "it-IT",
            "CEFR": "B2,C1",
            "Course topic": "Everyday Situations",
            "Course title": "Competenze specifiche: lettura e scrittura (B2/C1)",
            "Course ID": "b690ec9e4e4d28401c628db38bc6dcd4"
        },
        {
            "Learning language": "it-IT",
            "CEFR": "B1",
            "Course topic": "Everyday Situations",
            "Course title": "Tutte le competenze (B1)",
            "Course ID": "d18f71716e9d36e565e5e9f5c1e39e14"
        },
        {
            "Learning language": "it-IT",
            "CEFR": "B2,C1",
            "Course topic": "Everyday Situations",
            "Course title": "Tutte le competenze (B2/C1)",
            "Course ID": "2e9dcfeb797ad8e67efa6855e2c29af9"
        },
        {
            "Learning language": "it-IT",
            "CEFR": "B1,B2,C1",
            "Course topic": "Government and Politics",
            "Course title": "Video: politica ed economia",
            "Course ID": "70cf131b2b0de963a0b4524f68915348"
        },
        {
            "Learning language": "it-IT",
            "CEFR": "B1,B2,C1",
            "Course topic": "Health and Medicine",
            "Course title": "Video: medicina e salute",
            "Course ID": "efe4f531-c6bb-52b9-2284-8ff1d7416077"
        },
        {
            "Learning language": "it-IT",
            "CEFR": "B2,C1",
            "Course topic": "Sales and Marketing",
            "Course title": "Gestire la contabilità",
            "Course ID": "6a30adc5e7a8ab49063bbbb7f73fe097"
        },
        {
            "Learning language": "it-IT",
            "CEFR": "B2,C1",
            "Course topic": "Sales and Marketing",
            "Course title": "Stabilire relazioni con i clienti ",
            "Course ID": "92d83424042140bdb76f4433046888d4"
        },
        {
            "Learning language": "it-IT",
            "CEFR": "B1,B2,C1",
            "Course topic": "Technology",
            "Course title": "Video: tecnologia",
            "Course ID": "9025e19e-98b0-baee-7cad-6ea181ff1321"
        },
        {
            "Learning language": "it-IT",
            "CEFR": "B1,B2,C1",
            "Course topic": "The Environment and Physical Sciences",
            "Course title": "Video: lo spazio",
            "Course ID": "81df5a7ce225d70cac119a055238ac56"
        },
        {
            "Learning language": "it-IT",
            "CEFR": "B1,B2,C1",
            "Course topic": "The Environment and Physical Sciences",
            "Course title": "Video: scienze e ambiente",
            "Course ID": "ce85d44dc47682c95206688841c76d0c"
        },
        {
            "Learning language": "it-IT",
            "CEFR": "B1",
            "Course topic": "Travel and Hospitality",
            "Course title": "Accogliere ed accompagnare i clienti",
            "Course ID": "63529c5e75ff0dfecf23fa880ed4816e"
        }
    ]
}

export default courseList