import React from 'react'

const ExtraStudente = ({data}) => {
    const { cod_cds, des_cds, cod_ord, des_ord, cod_pds, des_pds, des_dip, cod_dip} = data
    return <div>
        <b>Dip:</b> [{cod_dip}] {des_dip}  <br />
        <b>Cds:</b> [{cod_cds}] {des_cds} <br />
        <b>Ord:</b> [{cod_ord}] {des_ord} <br />
        <b>Percorso:</b> [{cod_pds}] {des_pds}
    </div>
  
  }
  
export  const ExtraDipendente = ({data}) => {
  
      const {cod_role,des_role,des_profile,des_category,teacher} = data
  
      return <div>
        <b>Ruolo:</b> [{cod_role}] {des_role}
        <b>Profilo:</b>  {des_profile} <br />
        <b>Categoria:</b> {des_category} <br />
        {teacher === 'S' ? "Docente" : "Non Docente"}<br />
    </div>
  
  }

  export default ExtraStudente