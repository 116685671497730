import axios from 'axios'
import config from '../config'
const {users} = config;


export default function getUserDetails (token) 
{
    const axiosConfig = {
        headers: ({ 'Authorization': `Bearer ${token}` })
    }
    return axios.get(users.api, axiosConfig)
}
