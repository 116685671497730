import React from 'react'

export default function ElencoPlacements ({placements}){
    const firstEl = Array.isArray(placements) ? placements[0] : null
    const fields = firstEl ? Object.keys(firstEl) : null
    const fieldsObj = {}

    for(let t of fields) {fieldsObj[t] = ""}
   
    return <div>
            <div className="table-responsive">
                <table className="table table-hover">
                    <thead className="thead-dark">
                        <tr>{fields.map( th => {
                            return <td key={th}>{th}</td>
                        })} 
                        </tr>
                    </thead>
                    <tbody className="table-hover">
                        {placements.map( item => {
                            return <tr key={item["#ID"].toString() + Math.random()}>
                                {fields.map( col => {
                                    if(!(typeof item[col] === "object" )){
                                        return <td key={col}>
                                            {item[col].toString()}
                                        </td>
                                    } 
                                    return <td  key={col+Math.random()} className="text-center">[...]</td>
                                    })}
                                </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>

}