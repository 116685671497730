import React, {useState} from 'react'
import { getLanguages} from './apiActions'
import { Row, Col, ListGroup, Badge, ListGroupItem, Card, CardBody, CardFooter, Button, Input, Label, CardHeader, CardTitle} from 'reactstrap'
import courseList from './courseList'

const CourseForm = (item, setItem) => {
    const [minGoalPerc, setMinGoalPerc] = useState(0)
    
    const updateItem = () => {
        item.min = minGoalPerc
        setItem(item)

    }

    return <div>
            <Button onClick={updateItem}>Aggiorna</Button>{` `}
            <b>{item['Course Title']}</b>
            <p>Language: {item["Learning language"]}</p> 
            <p>CEFR: {item["CEFR"]}</p> 
            <p>Topic: {item['Course topic']}</p>
            <Input type="number" name="minimum" value={minGoalPerc} onChange={ (e)=>{setMinGoalPerc(e.target.value)}} /> 
            
        </div>

}



const LearningPathForm = ({action, data, token, formType, submit, update})=>{
    const {courses} = courseList

    const [lang, setLang] = useState([])
    const [inputData, setInputData] = useState(data)
    const {name, language} = inputData

    const coursesFields = Object.keys(courses[0])   

    const [filteredList, setFiltered] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [searchField, setSearchField] = useState("")
    const [courseBasket, setCourseBasket] = useState([])
    
    const CreaLearningPath = ()=>{
      action(inputData)
      submit(inputData)
    }

    const UpdateLearningPath = ()=>{
        update(courseList)
    }

    const addCourseToBasket = (item)=>{

    }


    React.useEffect(() => {
      getLanguages(token)
        .then( res => {
          const {data} = res
          setLang(data)
        })
        .catch( err => {
          console.log('ERRORE.' + err)
        })
    }, [token]);
  
  
    const LanguagesOptions = lang.map( 
      (item) =>( <option value={item.id} key={item.id}>
          {item.description}
      </option>)
    )
  
    const onChange = (e) => {
        const {value, name} = e.target
        setInputData( old=> ({...old, [name]: value}))
    }

    const onSearch = (e) => {
        setSearchTerm(e.target.value.toString())
    }
    const onSearchField = (e) => {
        setSearchField(e.target.value.toString())
    }

    const filterCourses = ()=> {
    const dataT = []
        for (const item of courses){
            if(item[searchField] && item[searchField].toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
                dataT.push(item)
        }
        if(dataT.length > 0){
            setFiltered(dataT)
        }    
    }
        
  
    return <><Row className="justify-content-center">
            <Col md="8">
                <Card>
                    <CardHeader>
                        <CardTitle><h4>Creazione Learning Path</h4></CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={8}>
                                <Label for="name">Learning Path Name{` `}</Label>
                                <Input width={700} type="text" name="name" value={name}  onChange={onChange}/>
                            </Col>
                            <Col md={4}>
                                <Label  for="language">Language</Label>
                                <select className="form-control" name="language" value={language} onChange={onChange}>
                                    <option value="" selected={true}>Seleziona Lingua</option>
                                    {LanguagesOptions}
                                </select>
                            </Col>
                        </Row>
                        <Row style={{paddingTop: 20, marginTop:10, borderTop: "1px solid grey"}}>
                            <Col>
                                <Label for="search">Ricerca</Label>
                                <select className="form-control" name="search" value={searchField} onChange={onSearchField}>
                                    {coursesFields.map(item => ( <option key={item} value={item}>{item}</option>)) }
                                </select>
                                <Input name="searchTerm" type="text" value={searchTerm} onChange={onSearch} />
                                <br />
                                <Button color="success" onClick={filterCourses}> <i className="fa fa-search"></i> Cerca</Button>
                                {courseBasket.length ? courseBasket.map( item => <CourseForm item={item} setItem={setCourseBasket} key={item.id} />) : null}
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                       {formType==="CREATE" ? <Button color="primary" onClick={CreaLearningPath}> Crea </Button>
                       : <Button color="primary" onClick={UpdateLearningPath}> Aggiorna </Button>}
                    </CardFooter>
                </Card>


            </Col>
      </Row>
      <Row>
        <Col>
            {searchTerm && filteredList.length ? <>
                <ListGroup>
                    {filteredList.map( item => (
                    <ListGroupItem key={item['Course ID']}>
                        {item["Learning language"]} <Badge>{item["CEFR"]}</Badge> <b>{item['Course title']}</b> <span>{item['Course topic']}</span> <Button size="sm" color="success" onClick={()=>addCourseToBasket(item)}>+</Button>
                    </ListGroupItem>))}
                </ListGroup>
            </>: null}
        </Col>
      </Row>
    </> 
  
}
  
export default LearningPathForm;  