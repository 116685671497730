import React , {useState, useCallback} from 'react'
import axios from 'axios'

// import convertData from './helper'
import DataTable from './tabella'

axios.defaults.timeout = 30000; // timeout necessario per non bloccare l'App

function TablePaged(props) {

  //const [lastModified, setLastModified] = useState("...in aggiornamento...")
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState({records: 0, pages: 0})
  const {url, columns,  remap, hiddenColumns, remapExport, useAdvance} = props

  const fetchData = useCallback( async ({pageIndex, pageSize, filters, globalFilter, token }) => {

//    const trash = trashed ? '_ne=0' :'_ne=1'

    let filter = ""
 //   let sort = (sortBy && sortBy.length) ? '&_sort=' + sortBy[0].id + ":" + (sortBy[0].desc ? "DESC" : "ASC") : ""
    if(globalFilter) {
        filter='&q='+globalFilter
    }

    for (let item of filters) {
      filter += `&${item.id}_contains=${item.value}`
    }


    //const newUrl = `${url}?_limit=${pageSize}&_start=${pageIndex}&trash${trash}${filter}${sort}`

    //const newCountUrl = `${url}/count?trash${trash}${filter}`

    const newUrl = `${url}?_limit=${pageSize}&_start=${pageIndex*pageSize}${filter}` //${sort}`

    
   /*
   const newCountUrl = `${url}/count?${filter}`
    FetchCount (newCountUrl,token)
    .then( (resp) =>{
      */
      //let records = resp.data
     
      const options =  {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          })
      }
    
      const response = await axios.get(newUrl, options)
      const result = remap(response.data)


      const records = response.headers['x-total-count'] || 5000
      setPageCount({records: records, pages: Math.ceil(records / pageSize)})
      setData(result)

  }, [url, remap])

return <DataTable 
      {...props}
      url={url}
      data={data} 
      fetchData={fetchData} 
      useAdvance={useAdvance}
      //lastModified={lastModified} 
      pageCount={pageCount}
      columns={columns}
      hiddenColumns = {hiddenColumns}
      remap={remapExport}
    /> 
}

export default TablePaged