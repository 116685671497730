import React from 'react'
import {Alert} from 'reactstrap'
import StoreContext from '../core'

export default function SupportTeam ({user}) {
    const {configs} = React.useContext(StoreContext)  
    
    if(!configs)
        return null

    if(!user.userData)
        return null

    const {cod_dip, des_dip = null} = user.userData
    const {support} = configs
    const elenco = support.studenti_unime_it.dip || "Assistenza "
    const email = elenco[cod_dip] && elenco[cod_dip].email ? elenco[cod_dip].email : ""

   if(!email) 
        return null

    return <Alert color="info" style={{marginTop: 20}}>
                <p className={'lead small'} style={{fontSize: 14}}>Per supporto (non trovi un obiettivo corretto, o qualsiasi altra informazione), 
                contattare il referente del dipartimento: <b>{des_dip}</b>: <a href={`mailto:${email}`} title={des_dip}>{email}</a>
                </p> 
            </Alert>
}