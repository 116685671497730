import React from 'react'
import {unified} from 'unified'
import remarkParse from 'remark-parse'
import remarkSlug from 'remark-slug'
import remarkToc from 'remark-toc'
import remarkGithub from 'remark-github'
import remarkRehype from 'remark-rehype'
import rehypeHighlight from 'rehype-highlight'
import rehypeReact from 'rehype-react'

const processor = unified()
  .use(remarkParse)
  .use(remarkSlug)
  .use(remarkToc)
  .use(remarkGithub, {repository: 'rehypejs/rehype-react'})
  .use(remarkRehype)
  .use(rehypeHighlight)
  .use(rehypeReact, {createElement: React.createElement})

  const CustomMessage = ({message}) => {
    return processor.processSync(message).result
  }

  export default CustomMessage