import React from 'react'
import {Alert, Collapse, Button} from 'reactstrap'

const UserData = ({userData}) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const toggle = () => setIsOpen(!isOpen)

    const {firstName, lastName, matricola, cod_cds= null, 
        des_cds = null, cod_dip = null , des_dip = null, 
        cod_role=null, des_role=null, des_profile = null, 
        des_category = null, teacher = null } = userData

    return (
        <Alert color="null" style={{borderTop: '1px solid #ddd', boderBottom: '1px solid #ddd'}}>
            <p className="text-secondary"> Utente: <b>{firstName} {lastName}</b> [{matricola}] <Button color="link" title="Dettagli Utente" onClick={()=>toggle()}>{isOpen ?<i className="fa fa-caret-up"></i> : <i className="fa fa-caret-down"></i>}</Button></p>
            <Collapse isOpen={isOpen} toggle={toggle} style={{backgroundColor: "#eee", padding: 20}}>
                {cod_dip ? <p className="text-secondary"> Dipartimento: <b>[{cod_dip}] - {des_dip}</b> </p> : ""}
                {cod_cds ? <p className="text-secondary"> Corso di Studi: <b>[{cod_cds}] - {des_cds}</b> </p> : ""}
                {cod_role ? <p className="text-secondary">Ruolo: [{cod_role}] {des_role}</p> : ""}
                {des_profile ? <p>Profilo:  {des_profile}</p>: ""}
                {des_category ? <p>Categoria: {des_category}</p> : ""}
                {teacher === 'S' ? <p className="text-secondary">Docente</p> : ""}

            </Collapse>
            
        </Alert>
    )
}

export default UserData