import React from 'react'
import {  Col, Card, CardHeader, CardBody , } from 'reactstrap';

const messages = {
  labelDipDes: "Dipartimento: ",
  labelCdsDes: "CdS: ",

}

export default function HomeProtected({user, userData}) {

  let displayName = user.username
  let struttura = ""

  if(userData && userData.firstName){
    const {firstName, lastName, dip_des, cds_cod, cds_des} = userData
    displayName = firstName + " " + lastName 
    struttura = dip_des ? 
    <span className="small">{messages.labelDipDes} <b>{dip_des}</b>
      <br/>{messages.labelCdsDes} <i className="small">[{cds_cod}] {cds_des}</i>
    </span> 
    : ""
  }

  return (
      <>
      <Col md={{size: 4, offset:4}} sm={{offset:4, size:4}} xs={{size:12}}>
        <Card>
          <CardHeader><small>Accesso eseguito come </small><b>{displayName }</b></CardHeader>
            <CardBody>
              {struttura ? <h6 className="text-left">
                  <b className="text-info"> </b>{struttura}
                </h6> : <></>}
            </CardBody>
        </Card>
      </Col>
      </>
  );
}
