import React, {useState}  from 'react'
import StoreContext from '../../core'
import getLearningPaths, {createLearningPath, updateCourses} from './apiActions'
import config from '../../../config'
import {Container, Row, Col, Button} from 'reactstrap'
import LearningPathForm from './learningPathForm'

const {appAdmin} = config;
const { pageTitle} = appAdmin.filter( (item) => (item.nameSpace === "learningPath"))[0]


export default function LearningPath(){

  const {token} = React.useContext(StoreContext) 
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [showInput, setShowInput] = useState(false)
  const [inputState, setInputState] = useState({})

  const activateInput = ()=>setShowInput(!showInput)

  React.useEffect( 
    () => 
    {
      getLearningPaths(token)
      .then( res => {
        const {data} = res
        setData(data)
      })
      .then(()=>{
        setLoading(false)
      })
      .catch( err => {
        console.log('ERRORE.' + err)
        setLoading(false)
      })

    }, [token])


  const onSubmit = (data) => {
    console.log(data)
    createLearningPath(token, data)
    .then( res => {
      const {id} = res.data
      setInputState( old => ( {...old, id:id}) )
      // bisogna attivare l'elenco dei corsi da inserire

      // e se c'è l'ID allora si trasforma il form in "AGGIORNA" e non in "CREA"
    })
    .catch(err=>{
      console.log(err)
      
    }
    )
  }

  const updateLP = (data) => {
    console.log(inputState, data)
    updateCourses(token, inputState.id, data)
  }



 return <Container>
  <Row className="justify-content-end align-items-center">
    <Col md={10}><h2 style={{padding: 24}}>{pageTitle}</h2></Col>
      <Col md={2}> 
        <Button color="primary" onClick={activateInput}> {showInput? "- Chiudi Form" : "+ Nuovo LP"} </Button>
      </Col>
      <Col md={12}>
      {showInput ? <LearningPathForm action={setInputState} data={inputState} submit={onSubmit} formType="CREATE" update={updateLP} token={token} /> : null}

      {loading ? 
      <>Sto caricando</>: 
      <>
        {data.map(
          item => {
            return <p key={item.id}>{item.name}</p>
          }
        )}
      </>
      }
    </Col>
    
   </Row>
 </Container>


}