import React, {useEffect, useState} from 'react'
import ElencoPlacements from './elencoPlacements'
import { Spinner } from 'reactstrap'
import getPlacements from './apiActions'
import StoreContext from '../core'


function remap (reportData = []) {
  console.log(reportData)
    const t = []
    for ( let report of reportData){
        const {id, language, testName, scaledScore, completedAt = null, 
          cefr,  
          cod_cds = null, cod_dip = null,
          cod_ord = null, des_cds = null, 
          des_dip = null, des_ord = null} = report

  const cds = `[${cod_cds}] ${des_cds}`
  const dip = `[${cod_dip}] ${des_dip}`
  const ord = `[${cod_ord}] ${des_ord}`

        t.push(
            {
            "#ID": id,
            "test": testName,
            "lingua": language, 
            "punteggio": scaledScore, 
            "livello": cefr,
            "data":completedAt,
            "cds": cds,
            "dip": dip,
            "ord": ord
        })
    }
    return t
}



export default function UsersPlacements () {
    const [loading, setLoading] = useState(true)
    const [placements, setPlacements] = useState([])
    const {token} = React.useContext(StoreContext)  


    useEffect(() => { 
      console.log('placements')
      getPlacements(token)
      .then( resp => {
        const data=  remap(resp.data)
        setPlacements(data)
      })
      .catch(err => {
        console.log(err)
        const data = remap()
        setPlacements(data)
      })
      .then( ()=> {
        setLoading(false)
      })
    }, [token])

    if(loading)
        return <div className="text-center">
            <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} /> 
            <p className="lead">Caricamento dati report lingua.</p>
        </div>

    return <>{ placements && placements.length 
                ? <ElencoPlacements placements={placements}/>
                : null}
        </>
}
export {ElencoPlacements, remap};