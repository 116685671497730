import axios from 'axios'
import config from '../../../config'

const {appAdmin} = config;
const {api} = appAdmin.filter( (item) => (item.nameSpace === "users"))[0]

export default function getUsers(token) {

    const axiosConfig = {
        headers: ({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
         })
    }
    return axios.get(api.getUsers, axiosConfig)
}

